export const operationsImportSchema = {
  type: 'object',
  properties: {
    estimate: { type: ['string', 'null'] },
    targetProjectId: { type: ['string', 'null'] },
    operations: {
      type: 'array',
      items: { type: 'string' },
    },
    headerParams: {
      type: 'object',
      properties: {
        operationHeader: { type: ['string', 'null'] },
        operationHeaderPosition: { type: ['number', 'null'] },
      },
    },
  },
  required: ['operations', 'targetProjectId'],
  additionalProperties: false,
};
