import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import ExcelJS from 'exceljs';
import { itemFields } from '~src/enums/time-tracker';
import { round, toNum } from '@oliasoft-open-source/units';
import { isEmpty } from 'lodash';
import { toast } from '@oliasoft-open-source/react-ui-library';
import { buildHeaders } from '../operations-table-builder';
import { formatTimeToExcel } from './date/dateUtils';
import { findSectionItemNameById } from './find-section-item-name';

async function writeExcelFile(workBook, fileName) {
  const buffer = await workBook.xlsx.writeBuffer(fileName);

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const borderStyle = { style: 'thin', color: { argb: 'D9D9D9' } };

const colorHeaderRow = (row) =>
  row.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E2E2DC' },
    };
    cell.border = {
      top: borderStyle,
      left: borderStyle,
      bottom: borderStyle,
      right: borderStyle,
    };
  });

export const downloadTimeTrackerExcel = ({
  wellName,
  company,
  totalAFE,
  startTime,
  estimatedFinish,
  variance,
  itemsList,
  disabledFields,
  operations,
  activities,
}) => {
  const workBook = new ExcelJS.Workbook();
  const workSheet = workBook.addWorksheet(
    i18n.t(translations.timeTracker_timeTracker),
  );

  const topHeaders = [
    i18n.t(translations.wellName),
    i18n.t(translations.company),
    i18n.t(translations.timeTracker_totalAFE),
    i18n.t(translations.timeTracker_startTime),
    i18n.t(translations.timeTracker_estimatedFinish),
    i18n.t(translations.timeTracker_variance),
  ];
  const topValues = [
    wellName,
    company,
    totalAFE,
    formatTimeToExcel(startTime),
    formatTimeToExcel(estimatedFinish),
    variance,
  ];

  workSheet.addRow(topHeaders);
  workSheet.addRow(topValues);
  workSheet.addRow();

  const rawHeaders = buildHeaders(disabledFields);
  workSheet.addRow();
  let i = 1;
  for (const headerCell of rawHeaders[0].cells) {
    const letter = String.fromCharCode(i + 64);
    workSheet.getCell(`${letter}4`).value = headerCell.value || '';
    if (headerCell.colSpan) {
      i += headerCell.colSpan;
      const colSpanletter = String.fromCharCode(i - 1 + 64);
      workSheet.mergeCells(`${letter}4:${colSpanletter}4`);
    } else {
      i += 1;
    }
  }
  colorHeaderRow(workSheet.lastRow);

  const headers = rawHeaders[1].cells.map((cell) => cell.value);
  workSheet.addRow(headers);
  colorHeaderRow(workSheet.lastRow);

  const getCells = (row) => {
    const formattedRow = {
      ...row,
      variance: row.actual ? round(row.actual - row.afe, 2) : '',
      start: formatTimeToExcel(row.start),
      finish: formatTimeToExcel(row.finish),
      section: row?.section?.name ?? '',
      operation:
        findSectionItemNameById(
          operations,
          row?.sectionsOperationId,
          'sectionsOperationId',
        ) ?? '',
      activity:
        findSectionItemNameById(
          activities,
          row?.sectionsOperationActivityId,
          'sectionsOperationActivityId',
        ) ?? '',
    };
    return Object.values(itemFields)
      .filter((item) => !disabledFields.includes(item))
      .map((item) => formattedRow[item] || '');
  };

  itemsList.forEach((operation) => {
    const operationRow = workSheet.addRow(getCells(operation));
    colorHeaderRow(operationRow);

    operation.activities.forEach((activity) => {
      workSheet.addRow(
        getCells({
          ...activity,
          sectionsOperationId: operation.sectionsOperationId,
          section: operation.section,
        }),
      );
    });
  });

  writeExcelFile(workBook, wellName);
};

const mapWorksheetToOperations = (worksheet, projectId) => {
  const operations = [];
  const activities = [];

  const headerValues = worksheet.getRow(2).values;
  const startTime = headerValues[4];
  worksheet.getCell('A6').value = startTime;

  const headerFieldMap = {
    'Start time': itemFields.START,
    'Operation/Activity': itemFields.NAME,
    AFE: itemFields.AFE,
    Estimate: itemFields.ESTIMATE,
    Actual: itemFields.ACTUAL,
    Variance: itemFields.VARIANCE,
    'Finish time': itemFields.FINISH,
    Planned: itemFields.PLANNED_DEPTH,
    'Activity Code': itemFields.ACTIVITY_CODE,
    'Sub-Operation Code': itemFields.SUB_OPERATION_CODE,
    'Operation Code': itemFields.OPERATION_CODE,
    'Actual Depth': itemFields.ACTUAL_DEPTH,
    Section: itemFields.SECTION,
    Activity: itemFields.ACTIVITY,
    Operation: itemFields.OPERATION,
  };
  const headerFields = worksheet.getRow(4).values.map((cell) => {
    return headerFieldMap[cell];
  });
  const formatExcelToApiDateTime = (time) => {
    const date = `${time.slice(6, 10)}-${time.slice(3, 5)}-${time.slice(
      0,
      2,
    )} ${time.slice(11, 13)}:${time.slice(14, 16)}:00`;
    return date;
  };

  const getCellValue = (value, key) => {
    switch (key) {
      case itemFields.PLANNED_DEPTH:
      case itemFields.ACTUAL_DEPTH:
      case itemFields.AFE:
      case itemFields.ESTIMATE:
      case itemFields.ACTUAL:
      case itemFields.VARIANCE:
        return value === '' ? 0 : toNum(value);
      case itemFields.START:
      case itemFields.FINISH:
        return formatExcelToApiDateTime(value);
      default:
        return value;
    }
  };

  const rowToActivityObject = (row) =>
    row.values.reduce(
      (acc, cell, index) => ({
        ...acc,
        [headerFields[index]]: getCellValue(cell, headerFields[index]),
      }),
      { projectId },
    );

  worksheet.eachRow((row, index) => {
    if (index > 5) {
      const operation = rowToActivityObject(row);
      if (row.getCell('A')?.fill?.pattern === 'solid') {
        operations.push({ ...operation, timeTrackerItemId: index.toString() });
      } else if (!isEmpty(operations)) {
        const lastOperation = operations[operations.length - 1];
        const activity = {
          ...operation,
          parentId: lastOperation.timeTrackerItemId,
        };
        activities.push(activity);
        if (lastOperation.activities) {
          lastOperation.activities.push(activity);
        } else {
          lastOperation.activities = [activity];
        }
      }
    }
  });
  return operations;
};

export const uploadTimeTrackerExcel = async (
  dispatch,
  uploadOperations,
  projectId,
) => {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = (fileEvent) => {
    const file = fileEvent.target.files[0];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async (readEvent) => {
      const data = readEvent.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.worksheets[0];
      const operations = mapWorksheetToOperations(worksheet, projectId);
      if (isEmpty(operations)) {
        toast({
          message: {
            type: 'Error',
            content: 'No operations',
          },
        });
      } else {
        dispatch(uploadOperations(operations));
      }
    };
  };
  input.click();
};
