import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Field,
  InputGroup,
  InputGroupAddon,
  Spacer,
  Text,
} from '@oliasoft-open-source/react-ui-library';
import { getOperations } from '~store/entities/activity-model/activity-model';
import { addRisk, initialRisk } from '~store/entities/risks/risks';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { risksSchema } from '~schemas/risks/risks.schema';
import {
  Input,
  CheckBoxList,
  NumberInput,
  RadioButton,
} from '~common/form-inputs';
import { useAutoSave } from '~src/common/auto-save/use-auto-save';
import { debounce } from 'lodash';
import { autoSaveWaitShort } from '~src/config/config';
import { EffectType } from '~src/enums/project-modifiers';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const RiskForm = ({
  risk,
  addRisk,
  getOperations,
  operations,
  projectId,
  isPageDisabled,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (projectId) {
      getOperations(projectId);
    }
  }, [projectId]);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialRisk,
    resolver: getResolver(risksSchema),
  });
  useEffect(() => {
    reset(risk || initialRisk);
  }, [risk?.riskId, reset]);

  const debounceAddRisk = useRef(debounce(addRisk, autoSaveWaitShort));
  const onSubmit = handleSubmit((data) => {
    data.projectId = projectId;
    debounceAddRisk.current(data);
  });
  useAutoSave(onSubmit, watch);
  const { name, effectType } = watch();
  return (
    <>
      <Heading top>{name}</Heading>
      <form>
        <InputGroup>
          <Field label="Name">
            <Input
              name="name"
              control={control}
              errors={errors}
              width="258px"
              disabled={isPageDisabled}
            />
          </Field>
          <Spacer width="var(--padding-sm)" />
          <Field label={t(translations.projectModifiers_effectType)}>
            <RadioButton
              name="effectType"
              control={control}
              inline={true}
              options={[
                {
                  label: t(translations.projectModifiers_timeIncrease),
                  value: EffectType.Increase,
                },
                {
                  label: t(translations.projectModifiers_timeDecrease),
                  value: EffectType.Decrease,
                },
              ]}
            />
          </Field>

          <Spacer width="var(--padding-sm)" />
          <Field
            label={t(
              effectType === EffectType.Increase
                ? translations.projectModifiers_timeIncrease
                : translations.projectModifiers_timeDecrease,
            )}
          >
            <InputGroup width={150}>
              <NumberInput
                name="effect"
                control={control}
                errors={errors}
                disabled={isPageDisabled}
              />
              <InputGroupAddon>%</InputGroupAddon>
            </InputGroup>
          </Field>
        </InputGroup>
        <Spacer />
        <Heading>{t(translations.risks_applyToOperations)}</Heading>
        <Spacer height="var(--padding-xs)" />
        <Text
          link
          onClick={() =>
            isPageDisabled
              ? false
              : setValue(
                  'operations',
                  operations.map((operation) => operation.id),
                )
          }
        >
          {t(translations.checkAll)}
        </Text>
        <Spacer width="var(--padding-sm)" />
        <Text
          link
          onClick={() => (isPageDisabled ? false : setValue('operations', []))}
        >
          {t(translations.uncheckAll)}
        </Text>
        <Spacer height="var(--padding-xs)" />
        <CheckBoxList
          items={operations}
          control={control}
          errors={errors}
          name="operations"
          disabled={isPageDisabled}
        />
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const operations = entities.activityModel.operations.map((operation) => ({
    id: operation.operationId.toString(),
    name: operation.name,
  }));

  return {
    isAdding: entities.risks.isAdding,
    operations,
  };
};

const mapDispatchToProps = { addRisk, getOperations };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(RiskForm),
);

export { Container as RiskForm };
