import {
  IChartData,
  DepthReferenceType,
  TCasingRendererDimensions,
  TWellDetails,
  TWellSchematic,
} from '../utils/types';
import { calculateDepthLabels } from './calculate-depth-labels';
import { calculateGrid } from './calculate-grid';
import { calculateLithology } from './calculate-lithology';
import { calculateMeta } from './calculate-meta';
import { calculateSections } from './calculate-sections';

export const calculateChartData = (
  wellSchematics: TWellSchematic[],
  wellDetail: TWellDetails,
  dimensions: TCasingRendererDimensions,
): IChartData => {
  const units = {
    depthReference: DepthReferenceType.MD,
    depthUnit: 'm',
    diameterUnit: 'in',
    densityUnit: 'sg',
  };
  const meta = calculateMeta(wellSchematics, wellDetail, units, dimensions);
  const grid = calculateGrid(meta);

  const sections = calculateSections(wellSchematics, meta);
  const lithology = calculateLithology(wellDetail, meta);

  const depthLabels = calculateDepthLabels(sections, meta);

  const data: IChartData = {
    sections,
    meta,
    grid,
    background: lithology,
    depthLabels,
  };
  return data;
};
