import { useEffect, useState } from 'react';
import {
  Input,
  InputGroup,
  Tooltip,
  Button,
  Menu,
  UnitInput,
} from '@oliasoft-open-source/react-ui-library';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { linkFields } from '~src/enums/settings';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawInputWithVariable = ({
  width = '180px',
  name,
  control,
  unit,
  unitkey = '',
  errors = {},
  error = null,
}) => {
  const { t } = useTranslation();
  const { field } = useController({
    name,
    control,
  });

  const { onChange, value, ...props } = field;
  const [peredefinedOption, setPredefinedOption] = useState(linkFields.CUSTOM);
  useEffect(() => {
    if (Object.values(linkFields).includes(value)) {
      setPredefinedOption(value);
      onChange(value);
    } else {
      setPredefinedOption(linkFields.CUSTOM);
      onChange(value);
    }
  }, []);
  const predefinedMenuItems = Object.values(linkFields).map((value) => ({
    type: 'Option',
    inline: true,
    onClick: () => {
      setPredefinedOption(value);
      onChange(value === linkFields.CUSTOM ? `0|${unit}` : value);
    },
    label: value,
    description: '',
    selected: value === peredefinedOption,
  }));
  return (
    <InputGroup width={width}>
      <Menu
        colored
        maxHeight={380}
        groupOrder="first"
        menu={{
          trigger: 'Component',
          component: (
            <Tooltip text={peredefinedOption}>
              <Button
                groupOrder="first"
                icon={
                  peredefinedOption === linkFields.CUSTOM ? 'unlink' : 'link'
                }
              />
            </Tooltip>
          ),
          sections: predefinedMenuItems,
        }}
      />
      {peredefinedOption === linkFields.CUSTOM &&
        !Object.values(linkFields).includes(value) && (
          <UnitInput
            {...props}
            onChange={onChange}
            value={value}
            unitkey={unitkey}
            initUnit={unit}
            error={
              translations[errors[name]]
                ? t(errors[name])
                : errors[name] || error
            }
          />
        )}
      {peredefinedOption !== linkFields.CUSTOM && (
        <Input {...props} value={value} disabled={true} />
      )}
    </InputGroup>
  );
};

export const InputWithVariable = withErrorBoundary(RawInputWithVariable);
