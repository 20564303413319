import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { drillingUnit, wellConfiguration } from '~src/enums/settings';
import { casingStringType, casingTypes } from '~src/enums/well-details';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseISO,
  format,
} from 'date-fns';
import { orderBy } from 'lodash';

export const initialWellDetails = {
  wellConfiguration: wellConfiguration.OFFSHORE,
  drillingUnit: drillingUnit.SEMI_SUBMERSIBLE,
};

export const initialSchematic = {
  schematicId: null,
  name: casingTypes.CONDUCTOR,
  type: casingStringType.CASING,
  section: '',
  top: 0,
  bottom: 0,
  sectiontd: 0,
  toc: 0,
  holeSize: '',
  displacementFluidDens: 0,
  wellEvents: [],
};

const wellDetails = createSlice({
  name: 'wellDetails',
  initialState: {
    isAdding: false,
    isFetching: false,
    isUpdating: false,
    isReordering: false,
    projects: [],
    well: {},
    schematic: [],
    countries: [],
    sections: [],
    additionalSections: [],
    wellEvents: [],
  },
  reducers: {
    wellDetailsRequested: (wellDetails) => {
      wellDetails.isFetching = true;
    },
    wellDetailsReceived: (wellDetails, action) => {
      wellDetails.isFetching = false;
      wellDetails.projects = action.payload.projects;
      wellDetails.well = action.payload.wellDetails;
      wellDetails.schematic = action.payload.schematic;
      wellDetails.countries = action.payload.countriesList;
      wellDetails.sections = action.payload.sections;
      wellDetails.additionalSections = action.payload.additionalSections;
      wellDetails.wellEvents = action.payload.wellEvents;
    },
    wellDetailsSaveRequested: (wellDetails) => {
      wellDetails.isAdding = true;
    },
    wellDetailsSaved: (wellDetails, action) => {
      wellDetails.isAdding = false;
      wellDetails.well = action.payload.wellDetails;
      wellDetails.additionalSections = action.payload.additionalSections;
    },
    schematicSaveRequested: (wellDetails) => {
      wellDetails.isAdding = true;
    },
    schematicSaved: (wellDetails, action) => {
      wellDetails.isAdding = false;
      wellDetails.schematic.push(action.payload.schematic);
    },
    schematicRemoved: (wellDetails, action) => {
      const { schematic } = wellDetails;
      const schematicIndex = schematic.findIndex(
        (item) => item.schematicId === action.payload.schematicId,
      );
      schematic.splice(schematicIndex, 1);
      wellDetails.schematic = schematic;
    },
    allSchematicsRemoved: (wellDetails) => {
      wellDetails.schematic = [];
    },
    cellValueUpdated: (wellDetails, action) => {
      const { id, value, field } = action.payload;
      const schematicIndex = wellDetails.schematic.findIndex(
        (item) => item.schematicId === id,
      );

      if (schematicIndex !== -1) {
        wellDetails.schematic[schematicIndex][field] = value;
      }
    },
    schematicUpdateRequested: (wellDetails) => {
      wellDetails.isUpdating = true;
    },
    schematicUpdated: (wellDetails) => {
      wellDetails.isUpdating = false;
    },
    schematicsReorderRequested: (wellDetails, action) => {
      wellDetails.isReordering = true;
      wellDetails.schematic = action.payload;
    },
    schematicsReordered: (wellDetails) => {
      wellDetails.isReordering = false;
    },
    wellDetailsCleanup: (wellDetails) => {
      wellDetails.well = {};
      wellDetails.schematic = [];
      wellDetails.wellEvents = [];
    },
  },
});

export const {
  wellDetailsRequested,
  wellDetailsReceived,
  wellDetailsSaveRequested,
  wellDetailsSaved,
  schematicSaveRequested,
  schematicSaved,
  schematicRemoved,
  cellValueUpdated,
  schematicUpdateRequested,
  schematicUpdated,
  allSchematicsRemoved,
  schematicsReorderRequested,
  schematicsReordered,
  wellDetailsCleanup,
} = wellDetails.actions;
export default wellDetails.reducer;

export const odDefaults = [
  '42',
  '40',
  '36',
  '32',
  '30',
  '26',
  '24',
  '22',
  '20',
  '18 5/8',
  '16',
  '14',
  '13 5/8',
  '13 1/2',
  '13 3/8',
  '11 7/8',
  '11 3/4',
  '10 3/4',
  '9 5/8',
  '8 5/8',
  '7 3/4',
  '7 5/8',
  '7',
  '6 5/8',
  '5 1/2',
  '5',
  '4 1/2',
  '4',
  '3 1/2',
  '2 7/8',
  '2 3/8',
  '2.063',
  '1.9',
  '1.66',
  '1.315',
  '1.05',
  '0',
];

export const holeSizeDefaults = [
  '42',
  '36',
  '26',
  '24',
  '22',
  '20',
  '17 1/2',
  '17',
  '16',
  '14 3/4',
  '14 1/4',
  '13 1/2',
  '12 1/4',
  '12',
  '10 5/8',
  '10 3/4',
  '9 7/8',
  '9 1/2',
  '9',
  '8 1/2',
  '7 7/8',
  '7',
  '6 1/2',
  '6 1/8',
  '6',
  '5 7/8',
];

/**
 * Get well details
 *
 * @param projectId
 */
export const getWellDetails = (projectId) =>
  apiCallBegan({
    url: `/api/well-details/${projectId}`,
    method: 'GET',
    onStart: wellDetailsRequested.type,
    onSuccess: (response) => ({
      type: wellDetailsReceived.type,
      payload: response,
    }),
  });

/**
 * Save well details
 *
 * @param data
 */
export const saveWellDetails = (data) =>
  apiCallBegan({
    url: `/api/well-details/${data.projectId}`,
    method: 'POST',
    data,
    onStart: wellDetailsSaveRequested.type,
    onSuccess: (response) => ({
      type: wellDetailsSaved.type,
      payload: response,
    }),
  });

/**
 * Link T&C project with welldesign
 *
 * @param data
 */
export const linkProject = (data) =>
  apiCallBegan({
    url: `/api/well-details/link-project`,
    method: 'POST',
    data,
    onStart: wellDetailsRequested.type,
    onSuccess: (response) => ({
      type: wellDetailsReceived.type,
      payload: response,
    }),
  });

/**
 * Add new schematic
 *
 * @param data
 */
export const addSchematic = (data) =>
  apiCallBegan({
    url: '/api/well-details/schematic',
    method: 'POST',
    data,
    onStart: schematicSaveRequested.type,
    onSuccess: (response) => ({
      type: schematicSaved.type,
      payload: response,
    }),
  });

/**
 * Remove schematic
 *
 * @param id
 * @param data
 */
export const removeSchematic = (id, data) =>
  apiCallBegan({
    url: `/api/well-details/schematic/${id}`,
    method: 'DELETE',
    data,
    onSuccess: (response) => ({
      type: schematicRemoved.type,
      payload: response,
    }),
  });

export const removeAllSchematics = (projectId) =>
  apiCallBegan({
    url: `/api/well-details/schematics/${projectId}`,
    method: 'DELETE',
    onSuccess: (response) => ({
      type: allSchematicsRemoved.type,
      payload: response,
    }),
  });

/**
 * Update schematic
 *
 * @param id
 * @param data
 * @param projectId
 */
export const updateSchematic = (id, data, projectId) =>
  apiCallBegan({
    url: `/api/well-details/schematic/${id}/${projectId}`,
    method: 'PUT',
    data,
    onStart: schematicUpdateRequested.type,
    onSuccess: (response) => ({
      type: schematicUpdated.type,
      payload: response,
    }),
  });

export const reorderSchematic = (reorder, list) => {
  const schematics = list.map((item) => item);
  const { to, from } = reorder;
  schematics.splice(to, 0, schematics.splice(from, 1)[0]);
  const data = schematics.map((schematic, i) => ({
    schematicId: schematic.schematicId,
    position: i,
  }));

  return apiCallBegan({
    url: '/api/well-details/schematics/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: schematicsReorderRequested.type,
      payload: schematics,
    }),
    onSuccess: schematicsReordered.type,
  });
};

export const timeAgo = (date) => {
  const currentDate = parseISO(new Date().toISOString());
  const inputDate = parseISO(date);

  const minutes = differenceInMinutes(currentDate, inputDate);
  const hours = differenceInHours(currentDate, inputDate);
  const days = differenceInDays(currentDate, inputDate);

  if (minutes < 1) {
    return 'Just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (days === 1) {
    return 'Yesterday';
  } else {
    const formattedDate = format(inputDate, 'yyyy-MM-dd');
    return formattedDate;
  }
};

export const filterAndSortDataRows = (projects, filters, sorts) => {
  const tableRows = projects.map(
    ({
      name,
      country,
      sort2,
      sort4,
      user_name: userName,
      accessed,
      updated,
      datasetid,
    }) => ({
      name,
      country,
      sort2,
      sort4,
      accessed,
      updated,
      userName,
      datasetid,
    }),
  );
  const matchesFilters = (row) =>
    Object.keys(filters).every((key) =>
      row[key]?.toLowerCase().includes(filters[key]?.toLowerCase()),
    );

  const filteredRows = tableRows.filter(matchesFilters);

  return orderBy(filteredRows, Object.keys(sorts), Object.values(sorts));
};

export const saveWellEvents = (projectId, data) =>
  apiCallBegan({
    url: `/api/well-details/section-events/${projectId}`,
    method: 'POST',
    data,
  });
