import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { getResolver } from '~src/validation/resolver';
import { compareSchema } from '~schemas/compare-estimates/compare.schema';
import { updateCompare } from '~store/entities/compare-estimates/compare-estimates';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const EditCompareModal = ({
  setModalVisible,
  isAdding,
  compare,
  updateCompare,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      compareId: compare?.compareId || null,
      name: compare?.name || '',
      isPublic: compare?.isPublic,
    },
    resolver: getResolver(compareSchema),
    mode: 'onChange',
  });
  const onSubmit = handleSubmit(async (data) => {
    await updateCompare(data);
    setModalVisible(false);
  });
  const onClose = () => setModalVisible(false);

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: `${t(translations.update)} ${t(translations.compare)}`,
          width: '600px',
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isAdding && (
                <Loader text={t(translations.fetching)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <Field label={t(translations.name)} labelLeft labelWidth="80px">
                  <Input
                    name="name"
                    control={control}
                    errors={errors}
                    width={400}
                  />
                </Field>
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isAdding } = entities.compareEstimates;

  return { isAdding };
};

const mapDispatchToProps = { updateCompare };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(EditCompareModal),
  { isModal: true },
);

export { Container as EditCompareModal };
