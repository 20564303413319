import { EffectType } from '~src/enums/project-modifiers';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const risksSchema = {
  type: 'object',
  properties: {
    name: {
      ...nonEmptyStringSchema,
    },
    projectId: { type: 'string' },
    riskId: { type: 'string' },
    certainty: { type: 'number', minimum: 0, maximum: 100 },
    effect: { type: 'number', minimum: 0, maximum: 1000 },
    operations: { type: 'array', items: { type: 'string' } },
    effectType: { type: 'string' },
    active: { type: 'boolean' },
    position: { type: 'number' },
  },
  if: { properties: { effectType: { enum: [EffectType.Decrease] } } },
  then: {
    properties: { effect: { type: 'number', minimum: 0, maximum: 100 } },
  },
  required: ['name'],
  additionalProperties: false,
};
