import { operationCodesTypes } from '~src/enums/settings';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const codesSchema = {
  type: 'object',
  properties: {
    codeId: { type: 'string' },
    codesListId: { type: 'string' },
    parentId: { type: ['string', 'null'] },
    code: {
      ...nonEmptyStringSchema,
    },
    description: {
      ...nonEmptyStringSchema,
    },
    type: { type: 'string', enum: Object.values(operationCodesTypes) },
    active: { type: 'boolean' },
  },
  required: ['code', 'description'],
  additionalProperties: false,
};
