export const offsetWellsSchema = {
  type: 'object',
  properties: {
    distance: { type: 'number' },
    waterDepth: { type: 'number' },
    wellheadDepth: { type: 'number' },
    casingStrings: { type: 'number' },
  },
  additionalProperties: false,
};
