import { Routes, Route } from 'react-router-dom';
import { HistoryRouter } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import {
  AuthenticationProvider,
  AuthenticationSecure,
} from '@oliasoft/authentication/pkce';
import '@oliasoft-open-source/react-ui-library/dist/global.css';
import { Toaster } from '@oliasoft-open-source/react-ui-library';

import { store, history } from '~store/configureStore';
import '../internationalisation/i18n.js';
import { routes } from '~routes/routes';

import { Companies } from '~views/companies/companies';
import { Projects } from '~views/projects/projects';
import { Project } from '~views/project/project';
import { NotFound } from '~views/not-found/not-found';
import { UserSettings } from '~views/settings/user-settings/user-settings';
import { Units } from '~views/settings/user-settings/units/units';
import { OperationCodes } from '~views/settings/company-settings/operation-codes/operation-codes';
import { UserTemplates } from '~views/settings/user-settings/user-tamplates/user-templates.jsx';
import { CompareEstimates } from '~views/compare-estimates/compare-estimates';
import { Campaigns } from '~views/campaigns/campaigns.jsx';
import { SocketProvider } from '~src/common/socket-contex/socket-context';
import ClientConfig from '~src/config/config';
import { CompanySettings } from '~views/settings/company-settings/company-settings.jsx';
import { Currency } from '~views/settings/company-settings/currency/currency.jsx';
import { TimeTracker } from '~views/settings/company-settings/time-tracker/time-tracker.jsx';
import { SentryConfiguration } from '~src/common/error-boundary/sentry-configuration.tsx';
import { Main } from './navigation/main/main';
import { UserKpi } from './settings/user-settings/user-kpi/user-kpi.jsx';
import { CompanyKpi } from './settings/company-settings/company-kpi/company-kpi.jsx';
import { CompanyTemplates } from './settings/company-settings/company-templates/company-templates.jsx';
import { SectionsDataStructure } from './settings/company-settings/sections-data-structure/sections-data-structure.tsx';
import './theme.css';
import { PriceBook } from './settings/company-settings/price-book/price-book.tsx';
import { initializeSentry } from '../../sentry.ts';

const App = () => {
  return (
    <Provider store={store}>
      <AuthenticationProvider
        history={history}
        idpServerUrl={ClientConfig.config.idpServerUrl}
        hostBaseUrl={ClientConfig.config.hostBaseUrl}
        clientId={ClientConfig.config.applicationId}
        userManagerSettings={{
          scope: `openid profile email ${ClientConfig.config.applicationId}`,
        }}
      >
        <HistoryRouter history={history}>
          {/** If no other error boundary catches the crash, then this will... */}
          <SentryConfiguration>
            <Routes>
              <Route
                index
                element={
                  <AuthenticationSecure>
                    <Companies />
                  </AuthenticationSecure>
                }
              />
              <Route
                {...routes.root.pattern}
                element={
                  <AuthenticationSecure>
                    <SocketProvider>
                      <Main />
                    </SocketProvider>
                  </AuthenticationSecure>
                }
              >
                <Route {...routes.projects.pattern} element={<Projects />} />
                <Route
                  {...routes.compare.pattern}
                  element={<CompareEstimates />}
                />
                <Route {...routes.campaigns.pattern} element={<Campaigns />} />
                <Route {...routes.project.pattern} element={<Project />} />
                <Route
                  {...routes.userSettings.pattern}
                  element={<UserSettings />}
                >
                  <Route
                    {...routes.userSettings.nested.units.pattern}
                    element={<Units />}
                  />
                  <Route
                    {...routes.userSettings.nested.templates.pattern}
                    element={<UserTemplates />}
                  />
                  <Route
                    {...routes.userSettings.nested.kpi.pattern}
                    element={<UserKpi />}
                  />
                </Route>
                <Route
                  {...routes.companySettings.pattern}
                  element={<CompanySettings />}
                >
                  <Route
                    {...routes.companySettings.nested.currency.pattern}
                    element={<Currency />}
                  />
                  <Route
                    {...routes.companySettings.nested.operationCodes.pattern}
                    element={<OperationCodes />}
                  />
                  <Route
                    {...routes.companySettings.nested.templates.pattern}
                    element={<CompanyTemplates />}
                  />
                  <Route
                    {...routes.companySettings.nested.kpi.pattern}
                    element={<CompanyKpi />}
                  />
                  <Route
                    {...routes.companySettings.nested.timeTracker.pattern}
                    element={<TimeTracker />}
                  />
                  <Route
                    {...routes.companySettings.nested.sectionsDataStructure
                      .pattern}
                    element={<SectionsDataStructure />}
                  />
                  <Route
                    {...routes.companySettings.nested.priceBook.pattern}
                    element={<PriceBook />}
                  />
                </Route>
                <Route {...routes.notFound.pattern} element={<NotFound />} />
              </Route>
            </Routes>
          </SentryConfiguration>
        </HistoryRouter>
      </AuthenticationProvider>
      <Toaster />
    </Provider>
  );
};

initializeSentry();

export default App;
