import { v4 as uuidv4 } from 'uuid';
import translations from '~src/internationalisation/translation-map.json';
import { addTaskOptions } from './consts';

export const initialTimeTrackerItem = (
  insertType,
  operation,
  activityIndex,
  clickedItem,
  t,
) => {
  const insertPosition =
    insertType === addTaskOptions.BELOW ? activityIndex + 1 : activityIndex;
  const calcPlannedDepth = () => {
    if (insertType === addTaskOptions.BELOW) {
      return clickedItem.plannedDepth;
    } else {
      return insertPosition === 0
        ? operation.depthFrom
        : operation.activities[insertPosition - 1].plannedDepth;
    }
  };
  return {
    name: t(translations.activityModel_newActivity),
    parentId: clickedItem.parentId,
    timeTrackerItemId: uuidv4(),
    start:
      insertType === addTaskOptions.BELOW
        ? clickedItem.finish
        : clickedItem.start,
    finish:
      insertType === addTaskOptions.BELOW
        ? clickedItem.finish
        : clickedItem.start,
    plannedDepth: calcPlannedDepth(),
  };
};
