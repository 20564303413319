import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  TbAlertTriangle,
  TbCalculator,
  TbChartLine,
  TbClock,
  TbSquareCheck,
} from 'react-icons/tb';
import wellIcon from '~src/assets/icons/well.svg';
import {
  Flex,
  Grid,
  Page,
  Heading,
  Row,
  Column,
  Spacer,
  Button,
  Divider,
} from '@oliasoft-open-source/react-ui-library';
import { routes } from '~routes/routes';
import { navigateToPath } from '~store/navigation/navigation';
import { ProjectSettingsModal } from '~src/views/settings/project-settings/project-settings.modal';
import {
  getProjectSettings,
  showProjectSettingsModalUpdated,
} from '~store/entities/project-settings/project-settings';
import translations from '~src/internationalisation/translation-map.json';
import './style.css';
import { ResultChartGrid } from '~src/views/simulations/result-overview/result-chart-grid';
import { AuditLog } from '~views/audit-log/audit-log';
import { updateNumberOfCharts } from '~src/store/entities/ui/simulations/chart-controls';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { PageCard } from './page-card';
import { OverviewDrawer } from './drawer/overview.drawer';

const Overview = ({
  projectOverview,
  navigateToPath,
  showProjectSettingsModal,
  showProjectSettingsModalUpdated,
  getProjectSettings,
  updateNumberOfCharts,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();

  useEffect(() => {
    getProjectSettings(project);
    updateNumberOfCharts(2);
  }, []);

  return (
    <>
      <Page padding={0} scroll={false}>
        <Row spacing={0} height="100%">
          <OverviewDrawer />
          <Column scroll>
            <div className="overview-wrapper">
              <div className="overview-header">
                <Flex justifyContent="space-between" gap>
                  <Heading top marginBottom={0}>
                    {projectOverview.name} ({t(translations.wellbore)})
                  </Heading>
                  <Flex gap>
                    <Button
                      name="project-settings"
                      label={t(translations.projects_projectSettings)}
                      onClick={() => showProjectSettingsModalUpdated(true)}
                    />
                    <Button
                      name="download-report"
                      label={t(translations.projects_downloadReport)}
                      onClick={() => {}}
                      disabled
                    />
                  </Flex>
                </Flex>
                <Spacer />
                <Grid
                  columns="repeat(6, 1fr)"
                  columnsTablet="repeat(3, 1fr)"
                  gap
                >
                  <PageCard
                    name="Define Well Details"
                    icon={wellIcon}
                    status={projectOverview?.pages?.wellDetails}
                    onClick={() =>
                      navigateToPath(routes.wellConfiguration.pattern.path)
                    }
                  />
                  <PageCard
                    name="Build Activity Model"
                    icon={<TbSquareCheck strokeWidth={1.25} />}
                    status={projectOverview?.pages?.activityModel}
                    onClick={() =>
                      navigateToPath(routes.activityModel.pattern.path)
                    }
                  />
                  <PageCard
                    name="Define Project Risks"
                    icon={<TbAlertTriangle strokeWidth={1.25} />}
                    status={projectOverview?.pages?.projectRisks}
                    onClick={() => navigateToPath(routes.risks.pattern.path)}
                  />
                  <PageCard
                    name="Build Cost Model"
                    icon={<TbCalculator strokeWidth={1.25} />}
                    status={projectOverview?.pages?.costModel}
                    onClick={() =>
                      navigateToPath(routes.costSetup.pattern.path)
                    }
                  />
                  <PageCard
                    name="Review Results"
                    icon={<TbChartLine strokeWidth={1.25} />}
                    status={projectOverview?.pages?.reviewResults}
                    onClick={() =>
                      navigateToPath(routes.resultOverview.pattern.path)
                    }
                  />
                  <PageCard
                    name="Export To Time Tracker"
                    icon={<TbClock strokeWidth={1.25} />}
                    status={projectOverview?.pages?.timeTracker}
                    onClick={() =>
                      navigateToPath(routes.timeTracker.pattern.path)
                    }
                  />
                </Grid>
              </div>
              <ResultChartGrid />
              <Divider margin={0} />
              <div className="overview-audit-log">
                <AuditLog />
              </div>
            </div>
          </Column>
        </Row>
      </Page>
      {showProjectSettingsModal && (
        <ProjectSettingsModal
          setModalVisible={showProjectSettingsModalUpdated}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  return {
    projectOverview: entities.projects.overview,
    showProjectSettingsModal: entities.projectSettings.showProjectSettingsModal,
  };
};

const mapDispatchToProps = {
  navigateToPath,
  showProjectSettingsModalUpdated,
  getProjectSettings,
  updateNumberOfCharts,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Overview),
  { isPage: true },
);

export { Container as Overview };
