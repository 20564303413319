import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Text,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import { CheckBoxList } from '~common/form-inputs';
import { getResolver } from '~src/validation/resolver';
import { navigateToRoute } from '~store/navigation/navigation';
import { routes } from '~views/navigation/routes/routes';
import { addKpis } from '~store/entities/campaigns/campaigns';
import { addKpiSchema } from '~schemas/campaigns/kpi.schema';
import translations from '~src/internationalisation/translation-map.json';

const KpiModal = ({
  kpi,
  campaign,
  isAdding,
  addKpis,
  kpiModalUpdated,
  navigateToRoute,
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      kpis: [],
    },
    resolver: getResolver(addKpiSchema),
    mode: 'onChange',
  });

  const onClose = () => kpiModalUpdated();
  const onSubmit = handleSubmit((data) => {
    addKpis(campaign.campaignId, data);
  });

  const kpiOptions = kpi.filter(
    (k) => !campaign?.kpisList.map((k) => k.id).includes(k.id),
  );

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: t(translations.campaigns_addKpis),
          content: (
            <form>
              <CheckBoxList
                items={kpiOptions || []}
                control={control}
                errors={errors}
                name="kpis"
              />
              <Spacer />
              <Text muted>
                {t(translations.campaigns_newKpisCanBeAddedIn)}
              </Text>{' '}
              <Text
                link
                onClick={() => {
                  onClose();
                  navigateToRoute(routes.userSettings.nested.kpi);
                }}
              >
                {t(translations.settings)}
              </Text>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.add)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    settings: { kpi },
    isAdding,
  } = entities.userSettings;

  return {
    kpi,
    isAdding,
  };
};

const mapDispatchToProps = { navigateToRoute, addKpis };

const Container = connect(mapStateToProps, mapDispatchToProps)(KpiModal);

export { Container as KpiModal };
