import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const kpiSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { ...nonEmptyStringSchema },
    valueType: { type: 'string' },
    numerator: { type: 'string' },
    numeratorUnit: { type: 'string' },
    denominator: { type: ['string', 'null'] },
    denominatorUnit: { type: ['string', 'null'] },
    isTarget: { type: 'boolean' },
    targetInequality: { type: ['string', 'null'] },
    target: { type: 'number' },
  },
  required: ['name', 'valueType', 'numerator', 'numeratorUnit', 'isTarget'],
  additionalProperties: false,
};
