import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Heading, Field, Button } from '@oliasoft-open-source/react-ui-library';
import {
  saveCodesList,
  initialCodesList,
} from '~store/entities/operation-codes/operation-codes';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { Input } from '~common/form-inputs';
import { codesListsSchema } from '~schemas/operation-codes/codes-lists.schema.ts';

const CodesListForm = ({ codesList, saveCodesList, isSaving }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialCodesList,
    resolver: getResolver(codesListsSchema),
  });

  useEffect(() => {
    reset(codesList || initialCodesList);
  }, [codesList, reset]);

  const onSubmit = handleSubmit((data) => {
    saveCodesList(data);
  });

  return (
    <>
      <Heading top>{t(translations.settings_codesListDetails)}</Heading>
      <form>
        <Field label={t(translations.name)}>
          <Input name="name" control={control} errors={errors} width="258px" />
        </Field>
        <Button
          name="save"
          label={t(translations.save)}
          onClick={onSubmit}
          colored
          disabled={isSaving}
        />
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isSaving } = entities.operationCodes;

  return {
    isSaving,
  };
};

const mapDispatchToProps = { saveCodesList };

const Container = connect(mapStateToProps, mapDispatchToProps)(CodesListForm);

export { Container as CodesListForm };
