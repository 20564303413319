import { useEffect, SetStateAction, Dispatch } from 'react';
import {
  Modal,
  Dialog,
  Button,
  useKeyboardEvent,
} from '@oliasoft-open-source/react-ui-library';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { Select } from '~common/form-inputs';
import { getResolver } from '~src/validation/resolver';
import { sectionSchema } from '~src/schemas/activity-model/section.schema';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import { useParams } from 'react-router';
import {
  addSection,
  showCreateSectionModalUpdated,
} from '~src/store/entities/activity-model/activity-model';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import type { TRootState } from '~src/store/store-types';

type CreateSectionModalProps = PropsFromRedux & {
  setActiveForm: Dispatch<SetStateAction<string | null>>;
};

const CreateSectionModal = ({
  sections,
  position,
  getSectionsDataStructure,
  addSection,
  setActiveForm,
  showCreateSectionModalUpdated,
  isAdding,
}: CreateSectionModalProps) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: getResolver(sectionSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    getSectionsDataStructure();
  }, []);

  const { project } = useParams();

  const { sectionId } = watch();

  const onClose = () => showCreateSectionModalUpdated(false);
  useKeyboardEvent('Escape', onClose);

  const onSubmit = handleSubmit((data) => {
    const section = {
      ...data,
      position,
    };
    if (project && section) {
      addSection(project, section);
      setActiveForm(null);
    }
  });

  const sectionList = sections.map((section: any) => ({
    label: section.name,
    value: section.sectionId,
  }));

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: t(translations.activityModel_addSection),
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              <form style={{ padding: 'var(--padding)' }}>
                <Select
                  name="sectionId"
                  options={sectionList}
                  control={control}
                  errors={errors}
                />
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.activityModel_addSection)}
                onClick={onSubmit}
                colored
                disabled={!sectionId || isAdding}
                loading={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { operations: activeOperations } =
    selectSectionsDataStructure(entities);
  const {
    activityModel: { sections, sectionItems, isAdding },
  } = entities;

  return {
    sections,
    activeOperations,
    position: sectionItems?.length,
    isAdding,
  };
};

const mapDispatchToProps = {
  getSectionsDataStructure,
  addSection,
  showCreateSectionModalUpdated,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(
  connector(CreateSectionModal),
  { isModal: true },
);

export { Container as CreateSectionModal };
