import translations from '~src/internationalisation/translation-map.json';

export const estimatesSchema = {
  type: 'object',
  properties: {
    estimates: {
      type: 'array',
      items: { type: 'object' },
      minItems: 1,
      errorMessage: translations.campaigns_selectAtLeastOneEstimate,
    },
  },
  required: ['estimates'],
  additionalProperties: false,
};
