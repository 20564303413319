import { validate } from '~src/validation/ajv';
import stringSchema from '~schemas/common/non-empty-string.schema';

export const validateString = (value) => {
  const validation = validate(value, stringSchema);
  if (!validation.valid) {
    return validation.errors[0].message;
  }
  return null;
};
