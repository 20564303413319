import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListSubheading,
  Menu,
  Button,
} from '@oliasoft-open-source/react-ui-library';
import {
  getOperations,
  operationSelected,
  operationsCleared,
  sectionSelected,
  reorderSections,
  showImportOperationModalUpdated,
} from '~store/entities/activity-model/activity-model';
import translations from '~src/internationalisation/translation-map.json';
import { getWellDetails } from '~store/entities/well-details/well-details';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const SectionsList = ({
  activeSection,
  isAdding,
  isPageDisabled,
  setSelectedId,
  setEstimateImportModalOpen,
  showCreateSectionModalUpdated,
  setSectionsDeleteModalVisible,
  sectionSelected,
  setActiveForm,
  sectionItems,
  reorderSections,
  showImportOperationModalUpdated,
}) => {
  const { t } = useTranslation();
  const onClickItem = (section) => {
    sectionSelected(section);
    setActiveForm(null);
  };
  const onClickDelete = (e, id) => {
    setSelectedId(e.shiftKey ? null : id);
    setSectionsDeleteModalVisible(true);
  };

  const items = sectionItems?.map((section) => {
    const { projectSectionId, name, sectionId } = section;
    return {
      id: projectSectionId,
      name,
      onClick: () => onClickItem({ projectSectionId, name, sectionId }),
      active: projectSectionId === activeSection?.projectSectionId,
      draggable: true,
      actions: [
        {
          label: 'Delete',
          icon: 'delete',
          onClick: (e) => onClickDelete(e, projectSectionId),
        },
      ],
    };
  });

  const disabled = isAdding || isPageDisabled;

  const menuGetExcel = (
    <Menu
      menu={{
        trigger: 'Component',
        component: (
          <Button small basic round colored="muted" icon="menu" disabled />
        ),
        sections: [
          {
            type: 'Option',
            icon: 'import',
            label: t(translations.importFromExcel),
            disabled,
            onClick: () => setEstimateImportModalOpen(true),
          },
          {
            type: 'Option',
            icon: 'add',
            label: t(translations.activityModel_addSection),
            onClick: () => showCreateSectionModalUpdated(true),
            disabled,
          },
          {
            type: 'Option',
            icon: 'delete',
            label: t(translations.activityModel_deleteAllSections),
            onClick: () => {
              setSelectedId(null);
              setSectionsDeleteModalVisible(true);
            },
            disabled,
          },
          {
            type: 'Option',
            icon: 'import',
            label: t(translations.activityModel_importFromExistingEstimate),
            onClick: () => showImportOperationModalUpdated(true),
            disabled,
          },
        ],
      }}
    />
  );

  return (
    <>
      <List
        list={{
          name: t(translations.activityModel_title),
          actions: [{ childComponent: menuGetExcel }],
          items: [],
        }}
      />
      <ListSubheading
        item={{
          name: 'Sections',
          actions: [
            {
              label: 'Add',
              icon: 'add',
              primary: true,
              onClick: () => showCreateSectionModalUpdated(true),
            },
          ],
        }}
      />
      <List
        list={{
          items,
        }}
        noHeader
        draggable={!isPageDisabled}
        onListReorder={(params) => reorderSections(params, sectionItems)}
      />
    </>
  );
};

const mapDispatchToProps = {
  getOperations,
  getWellDetails,
  operationSelected,
  operationsCleared,
  sectionSelected,
  reorderSections,
  showImportOperationModalUpdated,
};

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(SectionsList),
);

export { Container as SectionsList };
