import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Heading, Field } from '@oliasoft-open-source/react-ui-library';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { categoriesSchema } from '~src/schemas/settings/categories.schema';
import { Input } from '~common/form-inputs';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';

const CostCategoryForm = ({ category, entitySettings, updateCostCategory }) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const { settings, selectedId } = entitySettings;
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      costCategoryId: category?.costCategoryId || null,
      name: category?.name || '',
      parentId: null,
    },
    resolver: getResolver(categoriesSchema),
  });

  useEffect(() => {
    reset(category);
  }, [category]);

  const debounceAddCategory = useRef(
    debounce(updateCostCategory, autoSaveWait),
  );
  const onSubmit = handleSubmit((data) => {
    debounceAddCategory.current(data, settings, selectedId, companyId);
  });
  useAutoSave(onSubmit, watch);

  return (
    <>
      <Heading top>{t(translations.categoryDetails)}</Heading>
      <form>
        <Field label={t(translations.name)}>
          <Input name="name" control={control} errors={errors} width="258px" />
        </Field>
      </form>
    </>
  );
};

export { CostCategoryForm };
