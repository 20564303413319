import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { round } from '@oliasoft-open-source/units';
import { apiCallBegan } from '~store/middleware/api/api';
import translations from '~src/internationalisation/translation-map.json';
import { HOURS_PER_DAY } from '~src/enums/general';
import { timeCostView } from '~src/enums/simulations';

const slice = createSlice({
  name: 'simulations',
  initialState: {
    isFetching: false,
    simulationsResult: null,
    simulationStatus: {
      isSimulating: false,
      status: '',
      progress: '',
      message: '',
    },
  },
  reducers: {
    runSimulation: (simulations) => {
      simulations.simulationStatus.isSimulating = true;
      simulations.simulationStatus.status = i18n.t(translations.initializing);
      simulations.simulationStatus.progress = 0;
      simulations.simulationStatus.message = i18n.t(
        translations.simulations_startingSimulations,
      );
    },

    simulationDone: (simulations) => {
      simulations.simulationStatus.isSimulating = false;
    },

    calcRequested: (calculations) => {
      calculations.isFetching = true;
    },

    calcReceived: (simulations, action) => {
      simulations.isFetching = false;
      simulations.simulationsResult = action.payload;
    },

    updateSimulationStatus: (simulations, action) => {
      simulations.simulationStatus.status = action.payload.status;
      simulations.simulationStatus.progress = action.payload.progress;
      simulations.simulationStatus.message = action.payload.message;
      if (
        action.payload.status === 'Finished' ||
        action.payload.status === 'Error'
      ) {
        simulations.simulationStatus.isSimulating = false;
      }
    },

    simulationCleanup: (simulations) => {
      simulations.simulationsResult = null;
    },
  },
});

export const {
  runSimulation,
  simulationDone,
  calcRequested,
  calcReceived,
  updateSimulationStatus,
  simulationCleanup,
} = slice.actions;
export default slice.reducer;

/**
 * Run calculations
 *
 * @param projectId
 */
export const runSimulations = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'POST',
    onStart: runSimulation.type,
  });

/**
 * Get calculation list
 *
 * @param projectId
 */
export const getSimulations = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'GET',
    onStart: calcRequested.type,
    onSuccess: (response) => ({
      type: calcReceived.type,
      payload: response,
    }),
  });

/**
 * Get max probability
 *
 * @param simulation
 * @param chartType
 * @param probability
 * @param property
 * @returns {number|null}
 */
export const getMaxProbability = (
  simulation,
  chartType,
  probability,
  property,
) => {
  if (simulation?.[chartType]) {
    const data = simulation[chartType][probability][property];
    return property === timeCostView.TIME
      ? round(Math.max(...data) / HOURS_PER_DAY, 3)
      : round(Math.max(...data), 2);
  }
  return null;
};
