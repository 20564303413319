import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Row,
  Page,
  Loader,
  Spinner,
  Empty,
  Heading,
  Text,
  Spacer,
  Toggle,
  Button,
  Flex,
  Tabs,
} from '@oliasoft-open-source/react-ui-library';
import {
  getCampaigns,
  campaignModalOpened,
  estimateModalUpdated,
  learningCurveModalUpdated,
  kpiModalUpdated,
  updateLearningCurveFlag,
  welldesignModalUpdated,
  addConceptDesigns,
  getSimulation,
  addSimulationStatus,
  campaignSelected,
} from '~store/entities/campaigns/campaigns';
import { CampaignsList } from '~views/campaigns/campaigns.list';
import { DeleteCampaignModal } from '~views/campaigns/delete-campaign.modal';
import { CampaignModal } from '~views/campaigns/campaign.modal';
import { EstimateModal } from '~views/campaigns/estimate.modal';
import { Summary } from '~views/campaigns/summary/summary';
import translations from '~src/internationalisation/translation-map.json';
import { LearningCurveModal } from '~views/campaigns/learning-curve.modal';
import { KpiModal } from '~views/campaigns/kpi/kpi.modal';
import { selectPrimaryCurrency } from '~src/store/entities/company-settings/selectors';
import { CompareEstimatesDrawer } from '~views/compare-estimates/drawer/compare-estimates.drawer';
import { HierarchyDesignTableModal } from '~src/common/hierarchy-table/hierarchy-design-table.modal';
import {
  useSocket,
  WEBSOCKET_EVENTS,
} from '~src/common/socket-contex/socket-context';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { CampaignInputTab } from './campaign-tabs/campaign-input-tab';

const Campaigns = ({
  isFetching,
  campaignModalVisible,
  campaignModalOpened,
  estimateModalVisible,
  estimateModalUpdated,
  learningCurveModalVisible,
  learningCurveModalUpdated,
  kpiModalUpdated,
  kpiModalVisible,
  activeCampaign,
  getCampaigns,
  list,
  primaryCurrency,
  updateLearningCurveFlag,
  welldesignModalVisible,
  welldesignModalUpdated,
  designs,
  isAdding,
  countries,
  addConceptDesigns,
  conceptDesigns,
  getSimulation,
  addSimulationStatus,
  visibleEstimates,
  campaignSelected,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const { socket } = useSocket();

  const [selectedId, setSelectedId] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [deleteCampaignModalVisible, setDeleteCampaignModalVisible] =
    useState(false);
  const onClickDeleteCampaign = (id) => {
    setSelectedId(id);
    setDeleteCampaignModalVisible(true);
  };

  useEffect(() => {
    getCampaigns(company);
    socket.on(WEBSOCKET_EVENTS.notification_message, (m) =>
      addSimulationStatus(m.notification),
    );
    return () => {
      campaignSelected(null);
    };
  }, []);

  useEffect(() => {
    Promise.all(
      visibleEstimates.map((estimate) => {
        if (
          estimate?.simulation?.status === 'Finished' &&
          !estimate?.simulation?.fetched
        ) {
          return getSimulation(estimate.projectId);
        }
        return null;
      }),
    );
  }, [visibleEstimates]);

  return (
    <>
      <Page left={0} padding={false}>
        {(isFetching || isAdding) && (
          <Loader text={t(translations.fetching)} theme="light" cover>
            <Spinner dark />
          </Loader>
        )}
        <Row spacing={0} height="100%">
          <CampaignsList
            campaigns={list}
            onClickDelete={onClickDeleteCampaign}
            campaignModalOpened={campaignModalOpened}
          />
          {activeCampaign ? (
            <>
              {activeTabIndex === 1 && (
                <CompareEstimatesDrawer
                  estimates={activeCampaign.estimates}
                  filteredEstimates={[]}
                />
              )}
              <Column scroll padding>
                <Flex justifyContent="space-between" gap>
                  <Heading top>
                    {activeCampaign.name} <Spacer width="var(--padding)" />
                    <Text faint>
                      {activeCampaign.estimates?.length}{' '}
                      {t(translations.estimates)}
                    </Text>
                  </Heading>
                  <Flex gap alignItems="center">
                    <Toggle
                      label={t(translations.learningCurve)}
                      checked={activeCampaign.learningCurve}
                      onChange={() =>
                        updateLearningCurveFlag(activeCampaign.campaignId)
                      }
                      noMargin
                    />
                    <Button
                      label={t(translations.edit)}
                      onClick={() => learningCurveModalUpdated(true)}
                      small
                    />
                  </Flex>
                </Flex>
                <Tabs
                  value={activeTabIndex}
                  onChange={(e) => setActiveTabIndex(+e.target.value)}
                  margin={0}
                  options={[
                    {
                      value: 0,
                      label: t(translations.input),
                    },
                    {
                      value: 1,
                      label: t(translations.result),
                    },
                  ]}
                />
                {activeTabIndex === 0 && (
                  <CampaignInputTab
                    companyId={company}
                    activeCampaign={activeCampaign}
                    conceptDesigns={conceptDesigns}
                    visibleEstimates={visibleEstimates}
                  />
                )}
                {activeTabIndex === 1 && (
                  <Summary
                    activeCampaign={activeCampaign}
                    kpiModalUpdated={kpiModalUpdated}
                    primaryCurrency={primaryCurrency}
                  />
                )}
              </Column>
            </>
          ) : (
            <Empty
              height="100%"
              width="100%"
              text={t(translations.campaigns_noComparisonSelected)}
            />
          )}
        </Row>
      </Page>
      {deleteCampaignModalVisible && (
        <DeleteCampaignModal
          setModalVisible={setDeleteCampaignModalVisible}
          selectedId={selectedId}
        />
      )}
      {campaignModalVisible && (
        <CampaignModal campaign={activeCampaign} companyId={company} />
      )}
      {estimateModalVisible && (
        <EstimateModal
          campaign={activeCampaign}
          estimateModalUpdated={estimateModalUpdated}
        />
      )}
      {learningCurveModalVisible && (
        <LearningCurveModal
          campaign={activeCampaign}
          learningCurveModalUpdated={learningCurveModalUpdated}
        />
      )}
      {kpiModalVisible && (
        <KpiModal kpiModalUpdated={kpiModalUpdated} campaign={activeCampaign} />
      )}
      {welldesignModalVisible && (
        <HierarchyDesignTableModal
          setModalVisible={welldesignModalUpdated}
          getData={getCampaigns}
          designs={designs}
          isFetching={isFetching}
          isAdding={isAdding}
          addConceptDesigns={addConceptDesigns}
          countries={countries}
          name={activeCampaign?.name}
          campaignId={activeCampaign?.campaignId}
          conceptDesigns={conceptDesigns}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    isFetching,
    list,
    campaignModalVisible,
    estimateModalVisible,
    learningCurveModalVisible,
    kpiModalVisible,
    welldesignModalVisible,
    designs,
    isAdding,
    countries,
    estimates,
  } = entities.campaigns;
  const activeCampaign = list.find((item) => item.active);
  const primaryCurrency = selectPrimaryCurrency(entities);

  const activeEstimates = activeCampaign
    ? estimates.filter(
        (estimate) => estimate.campaignId === activeCampaign.campaignId,
      )
    : [];

  const visibleEstimates = activeEstimates.filter(
    (item) => item?.visible !== false,
  );
  const conceptDesigns =
    activeCampaign?.designs?.map(
      ({
        accessed,
        country: countryName,
        countryCode,
        datasetid: datasetId,
        name,
        sort1: fieldName,
        sort2: siteName,
        sort3: wellName,
        sort4: wellboreName,
        updated,
        user_name: userName,
      }) => ({
        accessed,
        countryCode,
        countryName,
        datasetId,
        name,
        fieldName,
        siteName,
        wellName,
        wellboreName,
        updated,
        userName,
      }),
    ) ?? [];

  return {
    list,
    isFetching,
    activeCampaign,
    campaignModalVisible,
    estimateModalVisible,
    learningCurveModalVisible,
    kpiModalVisible,
    primaryCurrency,
    welldesignModalVisible,
    designs,
    isAdding,
    countries,
    conceptDesigns,
    activeEstimates,
    visibleEstimates,
  };
};

const mapDispatchToProps = {
  getCampaigns,
  campaignModalOpened,
  estimateModalUpdated,
  learningCurveModalUpdated,
  kpiModalUpdated,
  updateLearningCurveFlag,
  welldesignModalUpdated,
  addConceptDesigns,
  getSimulation,
  addSimulationStatus,
  campaignSelected,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Campaigns),
  { style: { position: 'absolute', top: '50px' } },
);

export { Container as Campaigns };
