import React from 'react';
import { Button, Dialog, Modal } from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { addCategory } from '~store/entities/cost-setup/cost-setup';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { categoriesSchema } from '~src/schemas/cost-setup/categories.schema';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CostCategoryRenameModal = ({
  setModalVisible,
  category,
  addCategory,
  projectId,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      costCategoryId: category?.costCategoryId || null,
      name: category?.name || '',
      parentId: null,
    },
    resolver: getResolver(categoriesSchema),
    mode: 'onChange',
  });
  const onClose = () => setModalVisible(false);

  const onSubmit = handleSubmit((data) => {
    addCategory({ ...data, projectId });
    setModalVisible(false);
  });

  return (
    <Modal centered visible>
      <Dialog
        dialog={{
          heading: t(translations.renameCategory),
          content: (
            <form>
              <Input
                name="name"
                width="300px"
                control={control}
                errors={errors}
              />
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.rename)}
                colored
                onClick={onSubmit}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = { addCategory };

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(CostCategoryRenameModal),
  { isModal: true },
);

export { Container as CostCategoryRenameModal };
