import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Field,
  Button,
  Column,
} from '@oliasoft-open-source/react-ui-library';
import {
  saveUserSettings,
  initialUnitsSettings,
} from '~store/entities/user-settings/user-settings';
import { getResolver } from '~src/validation/resolver';
import { Select } from '~common/form-inputs';
import { unitsSchema } from '~schemas/settings/units.schema';
import { timeUnits, lengthUnits } from '~src/enums/settings';
import { getSelectOptions } from '~common/lists/lists';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const Units = ({ settings, lengthList, timeList, saveUserSettings }) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: getResolver(unitsSchema),
    defaultValues: initialUnitsSettings,
  });
  const onSubmit = handleSubmit((data) => {
    saveUserSettings({ ...settings, units: data });
  });
  useEffect(() => {
    reset(settings.units || initialUnitsSettings);
  }, [settings, reset]);

  return (
    <Column scroll padding spacing={0}>
      <Heading top>{t(translations.settings_unitsSettings)}</Heading>
      <form style={{ maxWidth: '75px' }}>
        <Field label={t(translations.length)}>
          <Select
            name="length"
            control={control}
            errors={errors}
            options={lengthList}
          />
        </Field>
        <Field label={t(translations.time)}>
          <Select
            name="time"
            control={control}
            errors={errors}
            options={timeList}
          />
        </Field>
        <Button
          name="save"
          label={t(translations.save)}
          onClick={onSubmit}
          colored
        />
      </form>
    </Column>
  );
};

const mapStateToProps = ({ entities }) => {
  const lengthList = getSelectOptions(lengthUnits);
  const timeList = getSelectOptions(timeUnits);

  return {
    settings: entities.userSettings.settings,
    lengthList,
    timeList,
  };
};
const mapDispatchToProps = { saveUserSettings };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(Units),
);

export { Container as Units };
