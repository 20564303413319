import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  Modal,
  Dialog,
  Button,
  Heading,
  Field,
  Spacer,
  InputGroup,
  InputGroupAddon,
  FormRow,
  HelpIcon,
} from '@oliasoft-open-source/react-ui-library';
import { initialActivity } from '~store/entities/user-settings/user-settings';
import { getCodesList } from '~store/entities/activity-model/activity-model';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { activitiesSchema } from '~schemas/settings/activities.schema';
import {
  Input,
  NumberInput,
  Select,
  TextArea,
  UnitInput,
  Toggle,
  InputWithVariable,
} from '~common/form-inputs';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import {
  convertInputUnits,
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';
import { isEmpty } from 'lodash';

const AddActivityFromSectionsDataModal = ({
  operation,
  codes,
  unitSettings,
  estimateTypesList,
  settings,
  isAdding,
  distributionTypesList,
  operations,
  activities,
  setModalVisible,
  addActivityFromSectionsData,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const activeOperation = operations.find(
    (item) => item.name === operation?.name,
  );
  const activeActivities = activities.filter(
    (item) => item.sectionsOperationId === activeOperation?.sectionsOperationId,
  );
  const activityOptions = activeActivities.map((activity) => ({
    label: activity.name,
    value: activity.name,
  }));

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: { ...initialActivity, name: activeActivities[0]?.name },
    resolver: getResolver(activitiesSchema),
  });

  const [estimateType, distributionType, min, most, max, isApplyFromOffset] =
    watch([
      'estimateType',
      'distribution',
      'min',
      'most',
      'max',
      'isApplyFromOffset',
    ]);
  const isOperationSpeed = estimateType === estimateTypes.OPERATION_SPEED;

  useEffect(() => {
    const activityData = {
      ...initialActivity,
      name: activeActivities[0]?.name,
    };
    const convertedLength = convertToInputUnits(
      activityData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    const convertedActivity = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed ? 'm' : 'h',
      isOperationSpeed ? unitSettings.length : unitSettings.time,
      true,
    );
    reset(convertedActivity);
  }, [unitSettings?.length, unitSettings?.time]);

  const { withBranch } = watch();

  useEffect(() => {
    if (withBranch) {
      clearErrors(['min', 'most', 'max', '']);
    }
  }, [withBranch, handleSubmit]);

  const onSubmit = handleSubmit((data) => {
    const convertedLength = convertToStorageUnits(data, ['from', 'to'], {
      from: 'm',
      to: 'm',
    });
    const convertedActivity = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed ? unitSettings.length : unitSettings.time,
      isOperationSpeed ? 'm' : 'h',
    );
    addActivityFromSectionsData(convertedActivity, settings, companyId);
  });
  const onClose = () => setModalVisible(false);

  useEffect(() => {
    trigger();
  }, [min, most, max]);

  const timeUnits = isOperationSpeed ? `${unitSettings.length}/hr` : 'hr';
  const activityCodesList = getCodesList(
    'activity',
    codes,
    operation.operationCode,
  );

  const distributionFieldMap = {
    [distributionTypes.PERT]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.mostLikely), name: 'most' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.UNIFORM]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.SPIKE]: [
      { label: t(translations.mostLikely), name: 'most' },
    ],
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: watch('name'),
          content: (
            <>
              <form>
                <Field label={t(translations.activity)}>
                  {activityOptions.length === 0 ? (
                    <Input name="name" control={control} errors={errors} />
                  ) : (
                    <Select
                      name="name"
                      options={activityOptions}
                      control={control}
                      errors={errors}
                    />
                  )}
                </Field>
                <InputGroup>
                  <Field label={t(translations.probability)}>
                    <InputGroup width={175}>
                      <NumberInput
                        name="certainty"
                        control={control}
                        errors={errors}
                      />
                      <InputGroupAddon>%</InputGroupAddon>
                    </InputGroup>
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.activityModel_activityCode)}>
                    <Select
                      name="activityCode"
                      control={control}
                      errors={errors}
                      options={activityCodesList}
                      width={250}
                    />
                  </Field>
                </InputGroup>
                <InputGroup>
                  <Field label={t(translations.estimateType)}>
                    <Select
                      name="estimateType"
                      control={control}
                      errors={errors}
                      options={estimateTypesList}
                      width="175px"
                      disabled={isApplyFromOffset}
                    />
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.activityModel_distributionType)}>
                    <Select
                      name="distribution"
                      control={control}
                      errors={errors}
                      options={distributionTypesList}
                      width="auto"
                      disabled={isApplyFromOffset}
                    />
                  </Field>
                </InputGroup>
                {estimateType === estimateTypes.OPERATION_SPEED && (
                  <>
                    <Heading>{t(translations.depth)}</Heading>
                    <Spacer />
                    <InputGroup>
                      <Field label={t(translations.from)}>
                        <InputWithVariable
                          name="from"
                          control={control}
                          unitkey={'length'}
                          unit={unitSettings?.length}
                          errors={errors}
                        />
                      </Field>
                      <Spacer width="var(--padding-sm)" />
                      <Field label={t(translations.to)}>
                        <InputWithVariable
                          name="to"
                          control={control}
                          unitkey={'length'}
                          unit={unitSettings?.length}
                          errors={errors}
                        />
                      </Field>
                    </InputGroup>
                  </>
                )}
                <Heading>
                  {estimateType === estimateTypes.OPERATION_SPEED
                    ? t(translations.activityModel_operationSpeed)
                    : t(translations.time)}
                </Heading>
                <Spacer />
                <FormRow>
                  {distributionFieldMap[distributionType]?.map((field) => (
                    <Field label={field.label} key={field.name}>
                      <UnitInput
                        name={field.name}
                        unit={timeUnits}
                        control={control}
                        errors={errors}
                        width="120px"
                        disabled={isApplyFromOffset}
                      />
                    </Field>
                  ))}
                </FormRow>
                <Field>
                  <Toggle
                    name="isApplyFromOffset"
                    label={t(
                      translations.activityModel_applyAllTimesFromOffsetWells,
                    )}
                    control={control}
                  />
                </Field>

                <Field label={t(translations.comments)}>
                  <TextArea
                    name="comments"
                    control={control}
                    errors={errors}
                    rows={5}
                  />
                </Field>
              </form>
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.save)}
                colored
                onClick={onSubmit}
                disabled={isAdding || !isEmpty(errors)}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    userSettings: { codes },
  } = entities;
  const { operations, activities } = selectSectionsDataStructure(entities);
  const estimateTypesList = [
    { label: 'Operation time', value: estimateTypes.OPERATION_TIME },
    { label: 'Operation speed', value: estimateTypes.OPERATION_SPEED },
  ];

  return {
    codes,
    estimateTypesList,
    operations,
    activities,
    distributionTypesList: [
      {
        label: 'Spike',
        value: distributionTypes.SPIKE,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_spikeDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Uniform',
        value: distributionTypes.UNIFORM,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_uniformDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Pert',
        value: distributionTypes.PERT,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_pertDistributionExplanation,
            )}
          />
        ),
      },
    ],
  };
};

const Container = connect(mapStateToProps)(AddActivityFromSectionsDataModal);

export { Container as AddActivityFromSectionsDataModal };
