import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const compareEstimatesSchema = {
  type: 'object',
  properties: {
    name: { ...nonEmptyStringSchema },
    estimates: {
      type: 'array',
      items: {
        type: 'object',
        properties: { projectId: { type: 'string' } },
      },
      minItems: 2,
    },
    addEstimate: { type: ['string', 'null'] },
    compareId: { type: 'string' },
  },
  required: ['name', 'estimates'],
  additionalProperties: false,
};
