import { validate } from '~src/validation/ajv';
import percentageSchema from '~schemas/common/percentage.schema';

export const validatePercentage = (value) => {
  const validation = validate(value, percentageSchema);
  if (!validation.valid) {
    return validation.errors[0].message;
  }
  return null;
};
