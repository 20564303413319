import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import { simulationStatuses } from '~src/enums/simulations';

const campaigns = createSlice({
  name: 'campaigns',
  initialState: {
    isAdding: false,
    isFetching: false,
    campaignModalVisible: false,
    estimateModalVisible: false,
    welldesignModalVisible: false,
    learningCurveModalVisible: false,
    kpiModalVisible: false,
    list: [],
    estimates: [],
    designs: [],
    estimatesList: [],
    countries: [],
  },
  reducers: {
    listRequested: (campaigns) => {
      campaigns.isFetching = true;
    },
    listFetched: (campaigns, action) => {
      campaigns.isFetching = false;
      campaigns.list = action.payload.campaigns;
      campaigns.estimatesList = action.payload.estimatesList;
      campaigns.estimates = action.payload.estimates.map((e) => ({
        ...e,
        visible: true,
      }));
      campaigns.designs = action.payload.designs;
      campaigns.countries = action.payload.countries;
    },
    campaignAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    campaignAdded: (campaigns, action) => {
      campaigns.isAdding = false;
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );

      if (existingIndex === -1) {
        const index =
          campaigns.list.push({
            ...action.payload,
            estimates: [],
            kpisList: [],
          }) - 1;
        campaigns.list = campaigns.list.map((item, i) => {
          item.active = i === index;
          return item;
        });
      } else {
        campaigns.list[existingIndex] = {
          ...campaigns.list[existingIndex],
          ...action.payload,
        };
        campaigns.list[existingIndex].active = true;
      }
    },
    campaignSelected: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      campaigns.list = list.map((item, i) => {
        item.active = i === index;
        return item;
      });
    },
    campaignRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      delete list[index];
      campaigns.list = list.filter((i) => i);
    },
    campaignModalOpened: (campaigns, action) => {
      const { list } = campaigns;
      const index = list.findIndex(
        (campaign) => campaign.campaignId === action.payload,
      );
      campaigns.list = list.map((item, i) => {
        item.active = i === index;
        return item;
      });

      campaigns.campaignModalVisible = true;
    },
    campaignModalClosed: (campaigns) => {
      campaigns.campaignModalVisible = false;
    },
    estimateModalUpdated: (campaigns, action) => {
      campaigns.estimateModalVisible = action.payload;
    },
    estimatesAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    estimatesAdded: (campaigns, action) => {
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (existingIndex !== -1) {
        campaigns.list[existingIndex] = {
          ...campaigns.list[existingIndex],
          estimates: action.payload.estimates,
        };
      }
      campaigns.isAdding = false;
      campaigns.estimateModalVisible = false;
    },
    estimateRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === action.payload.campaignId,
      );
      if (campaignIndex !== -1) {
        const { estimates } = list[campaignIndex];
        const estimateIndex = estimates.findIndex(
          (estimate) => estimate.campaignsEstimatesId === action.payload.id,
        );
        if (estimateIndex !== -1) {
          delete estimates[estimateIndex];
          campaigns.list[campaignIndex].estimates = estimates.filter((i) => i);
        }
      }
    },
    learningCurveModalUpdated: (campaigns, action) => {
      campaigns.learningCurveModalVisible = action.payload;
    },
    learningCurveUpdateRequested: (campaigns, action) => {
      campaigns.isAdding = true;
      const { campaignId, estimates } = action.payload;
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        for (const estimate of estimates) {
          const estimateIndex = list[campaignIndex].estimates.findIndex(
            (item) =>
              item.campaignsEstimatesId === estimate.campaignsEstimatesId,
          );
          if (estimateIndex !== -1) {
            campaigns.list[campaignIndex].estimates[estimateIndex].adjustment =
              estimate.adjustment;
          }
        }
      }
    },
    learningCurveUpdated: (campaigns) => {
      campaigns.isAdding = false;
      campaigns.learningCurveModalVisible = false;
    },
    learningCurveFlagUpdateRequested: (campaigns, action) => {
      const { campaignId } = action.payload;
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        campaigns.list[campaignIndex].learningCurve =
          !campaigns.list[campaignIndex].learningCurve;
      }
    },
    reorderEstimatesRequested: (campaigns, action) => {
      const { campaignId, list } = action.payload;
      const campaignIndex = campaigns.list.findIndex(
        (campaign) => campaign.campaignId === campaignId,
      );
      if (campaignIndex !== -1) {
        campaigns.list[campaignIndex].estimates = list;
      }
    },
    kpiModalUpdated: (campaigns, action) => {
      campaigns.kpiModalVisible = action.payload;
    },
    kpisAddRequested: (campaigns) => {
      campaigns.isAdding = true;
    },
    kpisAdded: (campaigns, action) => {
      const existingIndex = campaigns.list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (existingIndex !== -1) {
        campaigns.list[existingIndex].kpisList = action.payload.kpisList;
      }
      campaigns.isAdding = false;
      campaigns.kpiModalVisible = false;
    },
    kpiRemoved: (campaigns, action) => {
      const { list } = campaigns;
      const campaignIndex = list.findIndex(
        (campaign) => campaign.campaignId === action.payload.campaignId,
      );
      if (campaignIndex !== -1) {
        const { kpisList } = list[campaignIndex];
        const kpiIndex = kpisList.findIndex(
          (kpi) => kpi.id === action.payload.id,
        );
        if (kpiIndex !== -1) {
          delete kpisList[kpiIndex];
          campaigns.list[campaignIndex].kpisList = kpisList.filter((i) => i);
        }
      }
    },
    welldesignModalUpdated: (campaigns, action) => {
      campaigns.welldesignModalVisible = action.payload;
    },
    campaignConceptDesignsAdded: (campaigns, action) => {
      campaigns.isAdding = false;
      const { list } = campaigns;
      const index = list.findIndex(
        (item) => item.campaignId === action.payload.campaignId,
      );
      if (index !== -1) {
        action.payload.active = true;
        campaigns.list[index] = action.payload;
      }
    },
    conceptDesignRemoved: (campaigns, action) => {
      const { campaignId, datasetId } = action.payload;
      const { list } = campaigns;
      const index = list.findIndex((item) => item.campaignId === campaignId);
      const designIndex = list[index].designs.findIndex(
        (design) => design.datasetid === datasetId,
      );
      list[index].designs.splice(designIndex, 1);
    },
    campaignFetched: (campaigns, action) => {
      campaigns.isAdding = false;
      campaigns.list = action.payload.campaigns.map((campaign) => {
        campaign.active =
          campaign.campaignId === action.payload.campaign.campaignId;
        return campaign;
      });
      campaigns.estimates = action.payload.estimates;
      const activeCampaign = campaigns.list.find((campaign) => campaign.active);
      campaigns.estimates = campaigns.estimates.map((estimate) => {
        if (estimate.campaignId === activeCampaign.campaignId) {
          estimate.simulation = {};
          estimate.simulation.status = simulationStatuses.INITIALISING;
        }
        return estimate;
      });
      campaigns.estimatesList = action.payload.estimatesList;
    },
    simulationRequested: (campaigns, action) => {
      const { estimates } = campaigns;
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === action.payload.projectId,
      );
      if (index !== -1) {
        campaigns.estimates[index].isFetching = true;
      }
    },
    simulationFetched: (campaigns, action) => {
      const { projectId, ...simulation } = action.payload;
      const { estimates } = campaigns;
      campaigns.list.forEach((campaign) => {
        campaign.estimates.forEach((estimate) => {
          if (estimate.projectId === projectId) {
            estimate.simulation = simulation;
          }
        });
      });
      const index = estimates.findIndex(
        (estimate) => estimate.projectId === projectId,
      );
      if (index !== -1) {
        campaigns.estimates[index].simulationResult = action.payload;
        campaigns.estimates[index].isFetching = false;
        campaigns.estimates[index].simulation.fetched = true;
      }
    },

    addSimulationStatus: (campaigns, action) => {
      const { estimates } = campaigns;
      const index = estimates.findIndex((estimate) => {
        return estimate.jobId === action.payload.metadata.jobId;
      });
      if (index !== -1) {
        campaigns.estimates[index].simulation = {};
        campaigns.estimates[index].simulation.status = action.payload.status;
        campaigns.estimates[index].simulation.message = action.payload.message;
      }
    },
  },
});

export const {
  listRequested,
  listFetched,
  campaignAddRequested,
  campaignAdded,
  campaignSelected,
  campaignRemoved,
  campaignModalOpened,
  campaignModalClosed,
  estimateModalUpdated,
  estimatesAddRequested,
  estimatesAdded,
  estimateRemoved,
  learningCurveModalUpdated,
  learningCurveUpdateRequested,
  learningCurveUpdated,
  learningCurveFlagUpdateRequested,
  reorderEstimatesRequested,
  kpiModalUpdated,
  kpisAddRequested,
  kpisAdded,
  kpiRemoved,
  welldesignModalUpdated,
  campaignConceptDesignsAdded,
  conceptDesignRemoved,
  campaignFetched,
  simulationRequested,
  simulationFetched,
  addSimulationStatus,
} = campaigns.actions;
export default campaigns.reducer;

/**
 * Get campaigns data
 *
 * @param companyId
 */
export const getCampaigns = (companyId) =>
  apiCallBegan({
    url: `/api/campaigns/${companyId}`,
    method: 'GET',
    onStart: listRequested.type,
    onSuccess: (response) => ({
      type: listFetched.type,
      payload: response,
    }),
  });

/**
 * Add campaign
 *
 * @param companyId
 * @param data
 */
export const addCampaign = (companyId, data) =>
  apiCallBegan({
    url: `/api/campaigns/${companyId}`,
    method: 'POST',
    data,
    onStart: campaignAddRequested.type,
    onSuccess: (response) => ({
      type: campaignAdded.type,
      payload: response,
    }),
  });

/**
 * Add initial campaign
 *
 * @param companyId
 * @param name
 */
export const addInitialCampaign = (companyId, name, isConcept = false) =>
  addCampaign(companyId, { name, isConcept });

/**
 * Remove campaign
 *
 * @param id
 */
export const removeCampaign = (id) =>
  apiCallBegan({
    url: `/api/campaigns/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: campaignRemoved.type,
      payload: id,
    }),
  });

/**
 * Add estimates
 *
 * @param campaignId
 * @param data
 */
export const addEstimates = (campaignId, data) => {
  const estimates = data.estimates.map((estimate) => estimate.projectId);

  return apiCallBegan({
    url: `/api/campaign/${campaignId}/add-estimates`,
    method: 'POST',
    data: { estimates },
    onStart: estimatesAddRequested.type,
    onSuccess: (response) => ({
      type: estimatesAdded.type,
      payload: response,
    }),
  });
};

/**
 * Remove estimate
 *
 * @param id
 * @param campaignId
 */
export const removeEstimate = (id, campaignId) =>
  apiCallBegan({
    url: `/api/campaign/estimate/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: estimateRemoved.type,
      payload: { id, campaignId },
    }),
  });

/**
 * Update learning curve
 *
 * @param campaignId
 * @param data
 */
export const updateLearningCurve = (campaignId, data) => {
  const estimates = data.map((estimate) => ({
    campaignsEstimatesId: estimate.campaignsEstimatesId,
    adjustment: estimate.adjustment,
  }));

  return apiCallBegan({
    url: `/api/campaigns/learning-curve`,
    method: 'POST',
    data: { estimates },
    onStart: () => ({
      type: learningCurveUpdateRequested.type,
      payload: { campaignId, estimates },
    }),
    onSuccess: learningCurveUpdated.type,
  });
};

/**
 * Update learning curve flag
 *
 * @param campaignId
 */
export const updateLearningCurveFlag = (campaignId) =>
  apiCallBegan({
    url: `/api/campaigns/learning-curve/${campaignId}`,
    method: 'POST',
    data: {},
    onStart: () => ({
      type: learningCurveFlagUpdateRequested.type,
      payload: { campaignId },
    }),
  });

/**
 * Reorders estimates for a given campaign.
 *
 * @param {object} reorder - An object specifying the indexes to reorder.
 * @param {number} reorder.to - The index to move the estimate to.
 * @param {number} reorder.from - The index of the estimate to be moved.
 * @param {object} campaign - An object representing the campaign.
 * @param {number} campaign.campaignId - The ID of the campaign.
 * @param {array} campaign.estimates - An array of estimates for the campaign.
 * @param {number} campaign.estimates[].campaignsEstimatesId - The ID of the estimate.
 *
 * @return {Promise} A Promise representing the asynchronous API call. Resolves with the result of the API call.
 */
export const reorderEstimates = (reorder, campaign) => {
  const { campaignId, estimates } = campaign;
  const list = estimates.map((item) => item);
  const { to, from } = reorder;
  list.splice(to, 0, list.splice(from, 1)[0]);
  const data = list.map((estimate, i) => ({
    campaignsEstimatesId: estimate.campaignsEstimatesId,
    position: i,
  }));

  return apiCallBegan({
    url: '/api/campaign/estimate/reorder',
    method: 'POST',
    data,
    onStart: () => ({
      type: reorderEstimatesRequested.type,
      payload: { list, campaignId },
    }),
  });
};

/**
 * Adds KPIs to a campaign.
 *
 * @param {number} campaignId - The ID of the campaign.
 * @param {object} data - The data object containing the KPIs to be added.
 * @returns {Promise} A promise that resolves with the API response.
 */
export const addKpis = (campaignId, data) => {
  const kpis = data.kpis.map((kpi) => kpi);

  return apiCallBegan({
    url: `/api/campaign/${campaignId}/add-kpis`,
    method: 'POST',
    data: { kpis },
    onStart: kpisAddRequested.type,
    onSuccess: (response) => ({
      type: kpisAdded.type,
      payload: response,
    }),
  });
};

export const deleteKpi = (id, campaignId) =>
  apiCallBegan({
    url: `/api/campaign/kpi/${id}`,
    method: 'DELETE',
    onSuccess: () => ({
      type: kpiRemoved.type,
      payload: { id, campaignId },
    }),
  });

/**
 * Adds designs for welldesign campaigns
 *
 * @param {Object} data - .
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const addConceptDesigns = (data) =>
  apiCallBegan({
    url: 'api/campaign/concept-designs',
    method: 'POST',
    data,
    onStart: campaignAddRequested.type,
    onSuccess: campaignConceptDesignsAdded.type,
  });

/**
 * Removes the design item with the given campaignId and datasetID.
 *
 * @param {string} campaignId - The compare-ID of the concept design to remove.
 * @param {number} datasetId - The dataset-ID of the concept design to remove.
 *
 * @returns {Object} - The action object with type and payload properties.
 */
export const removeConceptDesign = (campaignId, datasetId) =>
  apiCallBegan({
    url: `/api/campaign/remove-concept-design`,
    method: 'DELETE',
    data: { campaignId, datasetId },
    onSuccess: conceptDesignRemoved.type,
  });

export const runConceptCampaign = (data) =>
  apiCallBegan({
    url: 'api/campaign/concept',
    method: 'POST',
    data,
    onStart: campaignAddRequested.type,
    onSuccess: (response) => ({
      type: campaignFetched.type,
      payload: response,
    }),
  });

/**
 * Get simulation data
 *
 * @param projectId
 */
export const getSimulation = (projectId) =>
  apiCallBegan({
    url: `/api/simulations/${projectId}`,
    method: 'GET',
    onStart: () => ({ type: simulationRequested.type, payload: { projectId } }),
    onSuccess: (response) => ({
      type: simulationFetched.type,
      payload: { projectId, ...response },
    }),
  });
