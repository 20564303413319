import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Loader,
  Spinner,
  Field,
  Heading,
  Flex,
  Spacer,
  Text,
  Grid,
} from '@oliasoft-open-source/react-ui-library';
import { Select, CheckBoxList } from '~common/form-inputs';
import {
  getOperationsImportData,
  importOperations,
} from '~store/entities/activity-model/activity-model';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { operationsImportSchema } from '~schemas/activity-model/operations-import.schema';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const ImportOperationModal = ({
  showImportOperationModalUpdated,
  getOperationsImportData,
  isOperationsImportFetching,
  operationsImportData,
  estimateOptions,
  projectId,
  importOperations,
  headerParams,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getOperationsImportData();
  }, []);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      estimate: null,
      operations: [],
      targetProjectId: projectId,
      headerParams,
    },
    resolver: getResolver(operationsImportSchema),
    mode: 'onChange',
  });
  const onClose = () => showImportOperationModalUpdated(false);
  const onSubmit = handleSubmit((data) => {
    importOperations(data);
  });
  const estimate = watch('estimate');
  const operations = watch('operations');
  const { sections } =
    operationsImportData.find((item) => item.projectId === estimate) || {};

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          width: '600px',
          heading: t(translations.activityModel_importFromExistingEstimate),
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isOperationsImportFetching && (
                <Loader text={t(translations.adding)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <Field label={t(translations.estimate)}>
                  <Select
                    options={estimateOptions}
                    name="estimate"
                    control={control}
                    errors={errors}
                  />
                </Field>
                <Flex justifyContent="space-between">
                  <Heading>{t(translations.activityModel_operations)}</Heading>
                  <div>
                    <Button
                      label={t(translations.checkAll)}
                      onClick={() =>
                        setValue(
                          'operations',
                          sections.flatMap((section) =>
                            section.operations.map((operation) => operation.id),
                          ),
                        )
                      }
                      small
                    />
                    <Button
                      label={t(translations.uncheckAll)}
                      onClick={() => setValue('operations', [])}
                      small
                    />
                  </div>
                </Flex>
                <Spacer height="var(--padding-xs)" />
                {(!sections || sections.length === 0) && (
                  <>
                    <Text faint>
                      {t(
                        translations.activityModel_noOperationsHaveBeenAddedYet,
                      )}
                    </Text>
                    <Spacer height="var(--padding-xs)" />
                  </>
                )}
                <Grid columns="1fr 1fr">
                  {sections?.map((section) => (
                    <div key={section.projectSectionId}>
                      <Spacer height="var(--padding-xs)" />
                      <Text faint>{section.name}</Text>
                      <CheckBoxList
                        items={section.operations}
                        control={control}
                        errors={errors}
                        name="operations"
                      />
                    </div>
                  ))}
                </Grid>
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.activityModel_importSelectedOperations)}
                colored
                onClick={onSubmit}
                disabled={isOperationsImportFetching || !operations.length}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    activityModel: { isOperationsImportFetching, operationsImportData },
  } = entities;
  const estimateOptions = operationsImportData.map((estimate) => ({
    label: estimate.name,
    value: estimate.projectId,
  }));

  return {
    isOperationsImportFetching,
    operationsImportData,
    estimateOptions,
  };
};

const mapDispatchToProps = {
  getOperationsImportData,
  importOperations,
};
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ImportOperationModal),
  { isModal: true },
);

export { Container as ImportOperationModal };
