export const schematicSchema = {
  type: 'object',
  properties: {
    schematicId: { type: 'string' },
    sectionId: { type: 'string' },
    wellDetailsId: { type: 'string' },
    name: { type: 'string' },
    type: { type: 'string' },
    section: { type: ['string', 'null'] },
    top: { type: ['number', 'null'] },
    bottom: { type: ['number', 'null'] },
    toc: { type: ['number', 'null'] },
    holeSize: { type: ['string', 'null'] },
    displacementFluidDens: { type: 'number' },
    sectiontd: { type: ['number', 'null'] },
  },
  additionalProperties: false,
};
