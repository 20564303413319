import translations from '~src/internationalisation/translation-map.json';
import { operationsSchema } from '~schemas/activity-model/operations.schema';
import { wellDetailsSchema } from '~schemas/well-details/well-details.schema';
import { schematicSchema } from '~schemas/well-details/schematic.schema';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const projectsSchema = {
  type: 'object',
  properties: {
    companyId: { type: 'string' },
    existingField: { type: ['string', 'null'] },
    existingSite: { type: ['string', 'null'] },
    existingWell: { type: ['string', 'null'] },
    existingWellbore: { type: ['string', 'null'] },
    projectData: {
      type: ['object', 'null'],
      properties: {
        operations: { type: 'array', items: { ...operationsSchema } },
        schematic: { type: 'array', items: { ...schematicSchema } },
        wellDetails: { ...wellDetailsSchema },
      },
    },
    estimate: { ...nonEmptyStringSchema },
    existingEstimate: { type: ['string', 'null'] },
  },
  oneOf: [
    {
      properties: {
        existingCountry: {
          type: 'string',
          errorMessage: translations.projects_countryIsRequired,
        },
      },
    },
    {
      properties: {
        country: {
          type: 'string',
          errorMessage: translations.projects_countryIsRequired,
        },
      },
    },
  ],
  allOf: [
    {
      if: {
        properties: { existingField: { const: null } },
      },
      then: {
        properties: {
          field: { ...nonEmptyStringSchema },
        },
      },
    },
    {
      if: {
        properties: { existingSite: { const: null } },
      },
      then: {
        properties: {
          site: { ...nonEmptyStringSchema },
        },
      },
    },
    {
      if: {
        properties: { existingWell: { const: null } },
      },
      then: {
        properties: {
          well: { ...nonEmptyStringSchema },
        },
      },
    },
    {
      if: {
        properties: { existingWellbore: { const: null } },
      },
      then: {
        properties: {
          wellbore: { ...nonEmptyStringSchema },
        },
      },
    },
  ],
};
