import { createSelector } from '@reduxjs/toolkit';

const selectUserSettingsStore = (entities) => entities.userSettings;

export const selectUserSettings = createSelector(
  [selectUserSettingsStore],
  (userSettingsStore) => userSettingsStore.settings,
);

export const selectUserTempaltes = createSelector(
  [selectUserSettings],
  (userSettings) => userSettings.templates,
);

export const selectUserSectionTypes = createSelector(
  [selectUserSettingsStore],
  (userSettingsStore) => userSettingsStore.sectionTypes,
);

export const selectActiveTemplateOperations = createSelector(
  [selectUserTempaltes],
  (templates) => templates.find((template) => template.active).activityModel,
);

export const selectGroupedOperations = createSelector(
  [selectUserSectionTypes, selectActiveTemplateOperations],
  (sections, operationList) =>
    sections.reduce((acc, { sectionId, name }) => {
      const sectionOperation = operationList.filter(
        ({ sectionType }) => sectionType === sectionId,
      );
      if (sectionOperation.length > 0) {
        acc.push({ sectionId, name, operations: sectionOperation });
      }
      return acc;
    }, []),
);
