import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Page,
  Spacer,
  Loader,
  Spinner,
  Row,
  Column,
} from '@oliasoft-open-source/react-ui-library';
import {
  getTimeTrackerItems,
  listCleared,
} from '~store/entities/time-tracker/time-tracker';
import { getSimulations } from '~store/entities/simulations/simulations';
import { getSectionsDataStructure } from '~src/store/entities/sections-data-structure/sections-data-structure';
import translations from '~src/internationalisation/translation-map.json';
import { OperationsTable } from '~views/time-tracker/operations-table';
import { selectIsPageDisabled } from '~src/store/entities/projects/selectors';
import { projectPages } from '~src/enums/projects';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { TimeTrackerHeader } from './time-tracker-header';
import { TimeTrackerDrawer } from './drawer/time-tracker.drawer';
import TimeTrackerChart from './time-tracker-chart';

const TimeTracker = ({
  itemsList,
  getTimeTrackerItems,
  getSectionsDataStructure,
  getSimulations,
  listCleared,
  isFetching,
  isPageDisabled,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();

  useEffect(() => {
    getTimeTrackerItems(project);
    getSectionsDataStructure();
    return () => listCleared();
  }, []);

  useEffect(() => {
    if (project) {
      getSimulations(project);
    }
  }, [project]);

  const [viewMode, setViewMode] = useState(1);

  const onViewModeChange = (key) => {
    setViewMode(key);
  };

  return (
    <Page padding={false} scroll={false}>
      {isFetching && (
        <Loader text={t(translations.fetching)} theme="light" cover>
          <Spinner dark />
        </Loader>
      )}
      <Row spacing={0} height="100%">
        <TimeTrackerDrawer />
        <Column padding={true} scroll>
          <TimeTrackerHeader
            itemsList={itemsList}
            isPageDisabled={isPageDisabled}
            onViewModeChange={onViewModeChange}
            viewMode={viewMode}
          />
          <Spacer />
          {itemsList?.length ? (
            <>
              {viewMode ? (
                <OperationsTable
                  itemsList={itemsList}
                  isPageDisabled={isPageDisabled}
                />
              ) : (
                <TimeTrackerChart operationList={itemsList} />
              )}
            </>
          ) : null}
        </Column>
      </Row>
    </Page>
  );
};

const mapStateToProps = ({ entities }) => {
  const { list, isFetching } = entities.timeTracker;

  return {
    itemsList: list,
    isFetching,
    isPageDisabled: selectIsPageDisabled(entities, projectPages.TIME_TRACKER),
  };
};

const mapDispatchToProps = {
  getTimeTrackerItems,
  getSimulations,
  getSectionsDataStructure,
  listCleared,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TimeTracker),
  { isPage: true },
);

export { Container as TimeTracker };
