import React from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { offsetWellsSchema } from '~schemas/offset-wells/offset-wells.schema';
import { getResolver } from '~src/validation/resolver';

import { useTranslation } from 'react-i18next';
import {
  Field,
  Select,
  InputGroup,
  InputGroupAddon,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  wellConfigurations,
  drillingUnits,
} from '~store/entities/user-settings/user-settings';
import { depthInequality, ANY_OPTION } from '~src/enums/settings';
import { listFilterFields } from '~src/enums/offset-wells';
import { NumberInput } from '~src/common/form-inputs';

const EstimatesFilter = ({
  wellConfigurationOptions,
  drillingUnitOptions,
  inequalityOptions,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();
  const onChange = (e, field) => {
    const { value } = e.target;
    let processedValue = value;

    if (
      [
        listFilterFields.WATER_DEPTH,
        listFilterFields.WELLHEAD_DEPTH,
        listFilterFields.CASING_STRINGS,
      ].includes(field)
    ) {
      processedValue = +value;
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      [field]: processedValue,
    }));
  };
  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: getResolver(offsetWellsSchema),
  });

  return (
    <div style={{ padding: 20 }}>
      <Field label={t(translations.distance)} labelLeft labelWidth={120}>
        <InputGroup small width={200}>
          <NumberInput
            errors={errors}
            control={control}
            name={listFilterFields.DISTANCE}
            value={filter.distance}
            onChange={(e) => onChange(e, listFilterFields.DISTANCE)}
          />
          <InputGroupAddon>m</InputGroupAddon>
        </InputGroup>
      </Field>
      <Field
        label={t(translations.settings_wellConfiguration)}
        labelLeft
        labelWidth={120}
      >
        <Select
          name={listFilterFields.WELL_CONFIGURATION}
          options={wellConfigurationOptions}
          value={filter.wellConfiguration}
          onChange={(e) => onChange(e, listFilterFields.WELL_CONFIGURATION)}
          width="200px"
          small
        />
      </Field>
      <Field
        label={t(translations.settings_drillingUnit)}
        labelLeft
        labelWidth={120}
      >
        <Select
          name={listFilterFields.DRILLING_UNIT}
          options={drillingUnitOptions}
          value={filter.drillingUnit}
          onChange={(e) => onChange(e, listFilterFields.DRILLING_UNIT)}
          width="200px"
          small
        />
      </Field>
      <Field
        label={t(translations.settings_waterDepth)}
        labelLeft
        labelWidth={120}
      >
        <InputGroup small>
          <Select
            name={listFilterFields.WATER_DEPTH_INEQUALITY}
            options={inequalityOptions}
            value={filter.waterDepthInequality}
            onChange={(e) =>
              onChange(e, listFilterFields.WATER_DEPTH_INEQUALITY)
            }
          />
          <NumberInput
            name={listFilterFields.WATER_DEPTH}
            value={filter.waterDepth}
            errors={errors}
            control={control}
            small={true}
            onChange={(e) => onChange(e, listFilterFields.WATER_DEPTH)}
            width={70}
          />
          <InputGroupAddon>m</InputGroupAddon>
        </InputGroup>
      </Field>
      <Field
        label={t(translations.settings_wellheadDepth)}
        labelLeft
        labelWidth={120}
      >
        <InputGroup small>
          <Select
            name={listFilterFields.WELLHEAD_DEPTH_INEQUALITY}
            options={inequalityOptions}
            value={filter.wellheadDepthInequality}
            onChange={(e) =>
              onChange(e, listFilterFields.WELLHEAD_DEPTH_INEQUALITY)
            }
          />
          <NumberInput
            name={listFilterFields.WELLHEAD_DEPTH}
            control={control}
            errors={errors}
            small={true}
            width={70}
            value={filter.wellheadDepth}
            onChange={(e) => onChange(e, listFilterFields.WELLHEAD_DEPTH)}
          />
          <InputGroupAddon>m</InputGroupAddon>
        </InputGroup>
      </Field>
      <Field
        label={t(translations.wellDetails_casingStrings)}
        labelLeft
        labelWidth={120}
      >
        <InputGroup small>
          <Select
            name={listFilterFields.CASING_STRINGS_INEQUALITY}
            options={inequalityOptions}
            value={filter.casingStringsInequality}
            onChange={(e) =>
              onChange(e, listFilterFields.CASING_STRINGS_INEQUALITY)
            }
          />
          <NumberInput
            small={true}
            errors={errors}
            control={control}
            name={listFilterFields.CASING_STRINGS}
            value={filter.casingStrings}
            onChange={(e) => onChange(e, listFilterFields.CASING_STRINGS)}
            width={100}
          />
        </InputGroup>
      </Field>
    </div>
  );
};

const mapStateToProps = () => {
  const anyOption = {
    value: ANY_OPTION,
    label: 'Any',
  };
  const wellConfigurationOptions = wellConfigurations.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));
  wellConfigurationOptions.push(anyOption);

  const drillingUnitOptions = drillingUnits.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));
  drillingUnitOptions.push(anyOption);

  const inequalityOptions = [
    {
      label: i18n.t(translations.settings_lessOrEqualTo),
      value: depthInequality.LESS_OR_EQUAL,
    },
    {
      label: i18n.t(translations.settings_moreThanOrEqual),
      value: depthInequality.MORE_THAN_OR_EQUAL,
    },
  ];

  return {
    wellConfigurationOptions,
    drillingUnitOptions,
    inequalityOptions,
  };
};

const mapDispatchToProps = {};

const Container = connect(mapStateToProps, mapDispatchToProps)(EstimatesFilter);

export { Container as EstimatesFilter };
