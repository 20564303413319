import { useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Icon,
  Button,
  Flex,
  Spacer,
  Text,
  Card,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import emptyIcon from '~src/assets/empty.svg';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { compareSchema } from '~src/schemas/compare-estimates/compare.schema';
import { Input, RadioButton } from '~common/form-inputs';
import { debounce } from 'lodash';
import { useAutoSave } from '~src/common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import { updateCompare } from '~store/entities/compare-estimates/compare-estimates';
import {
  ICompareDesignItem,
  ICompareEstimateListItem,
  ICompareActiveEstimate,
  ICompareEstimates,
  ICompareListItem,
} from '~src/common/interfaces/compare-estimates.interfaces';
import { selectCompanyNameById } from '~store/entities/companies/selectors';
import { selectSubjectId } from '~store/entities/authorization/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { CompareEstimatesInputTable } from './compare-estimates-input-table';
import type { TRootState } from '~src/store/store-types';

interface ICompareEstimatesInputTab extends PropsFromRedux {
  companyName: string;
  currentUserId: string;
  visibleEstimates: ICompareEstimates[];
  compare: ICompareListItem;
  showCompareEstimatesModalUpdated: (value: boolean) => void;
  showCompareModalUpdated: (value: boolean) => void;
  filteredEstimates: ICompareEstimateListItem[];
  compareDesigns: ICompareDesignItem[];
  activeEstimates: ICompareActiveEstimate[];
}

const CompareEstimatesInputTab = ({
  companyName,
  currentUserId,
  visibleEstimates,
  compare,
  updateCompare,
  showCompareEstimatesModalUpdated,
  showCompareModalUpdated,
  filteredEstimates,
  compareDesigns,
  activeEstimates,
}: ICompareEstimatesInputTab) => {
  const { isConcept, compareId, name, subjectId, designs } = compare;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      compareId: compareId || null,
      name: name || '',
      designs,
    },
    resolver: getResolver(compareSchema),
  });

  useEffect(() => {
    reset(compare);
  }, [compare]);

  const debounceUpdateCompare = useRef(debounce(updateCompare, autoSaveWait));

  const onSubmit = handleSubmit((data) => {
    debounceUpdateCompare.current(data);
  });

  useAutoSave(onSubmit, watch);

  const showTable = visibleEstimates?.length > 0 || compareDesigns?.length > 0;

  return (
    <>
      <Spacer />
      <Flex>
        <Field label={t(translations.compare_comparisonName)}>
          <Input
            name="name"
            control={control}
            errors={errors}
            width={300}
            placeholder={t(translations.compare_comparisonName)}
          />
        </Field>
        <Spacer width={16} />
        <Field label={t(translations.projects_accessControl)}>
          <RadioButton
            name="isPublic"
            control={control}
            disabled={subjectId !== currentUserId}
            inline={true}
            options={[
              {
                label: t(translations.projects_onlyMe),
                value: 'false',
              },
              {
                label: companyName,
                value: 'true',
              },
            ]}
          />
        </Field>
      </Flex>
      <Spacer />
      {!showTable && (
        <Card bordered={true}>
          <Flex
            alignItems="center"
            justifyContent="center"
            direction="column"
            height="25vh"
          >
            <Icon icon={emptyIcon} />
            <Spacer height="var(--padding-xs)" />
            <Text faint>
              {t(translations.compare_noEstimatesHaveBeenAddedYet, {
                value: isConcept
                  ? t(translations.designs)
                  : t(translations.estimates),
              })}
            </Text>
            <Spacer height="var(--padding-xs)" />
            <Button
              name="addEstimates"
              label={t(translations.compare_addEstimates, {
                value: isConcept
                  ? t(translations.designs)
                  : t(translations.estimates),
              })}
              onClick={() =>
                isConcept
                  ? showCompareModalUpdated(true)
                  : showCompareEstimatesModalUpdated(true)
              }
              colored
              disabled={false}
            />
          </Flex>
        </Card>
      )}
      {showTable && (
        <CompareEstimatesInputTable
          showCompareModalUpdated={showCompareModalUpdated}
          showCompareEstimatesModalUpdated={showCompareEstimatesModalUpdated}
          isConcept={isConcept}
          filteredEstimates={filteredEstimates}
          compareId={compareId}
          compareDesigns={compareDesigns}
          name={name}
          activeEstimates={activeEstimates}
          subjectId={currentUserId}
        />
      )}
    </>
  );
};

const mapStateToProps = ({entities}: TRootState, {companyId}: {companyId: string}) =>
  // @ts-expect-error store not ts
    ({companyName: selectCompanyNameById(entities, companyId),
      currentUserId: selectSubjectId(entities)
    });

const mapDispatchToProps = { updateCompare };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(CompareEstimatesInputTab));

export { Container as CompareEstimatesInputTab };
