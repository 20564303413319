import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import translations from '~src/internationalisation/translation-map.json';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const activitiesSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    parentId: {
      anyOf: [{ type: 'string' }, { type: 'null' }, { type: 'number' }],
    },
    name: {
      ...nonEmptyStringSchema,
    },
    certainty: { type: 'number', minimum: 0, maximum: 100 },
    estimateType: { type: 'number', enum: Object.values(estimateTypes) },
    from: { type: ['string', 'number'] },
    to: { type: ['string', 'number'] },
    min: { type: ['string', 'number'] },
    most: { type: ['string', 'number'] },
    max: { type: ['string', 'number'] },
    active: { type: 'boolean' },
    position: { type: 'number' },
    activityCode: { type: ['string', 'null'] },
    comments: { type: 'string' },
    distribution: { type: 'string', enum: Object.values(distributionTypes) },
    withBranch: { type: 'boolean' },
    isBranch: { type: 'boolean' },
    isApplyFromOffset: { type: 'boolean' },
    sectionsOperationActivityId: { type: ['string', 'null'] },
  },
  required: ['name'],
  additionalProperties: false,
  if: {
    properties: {
      withBranch: {
        enum: [false],
      },
    },
  },
  then: {
    allOf: [
      {
        if: {
          properties: {
            estimateType: {
              const: estimateTypes.OPERATION_TIME,
            },
          },
        },
        then: {
          allOf: [
            {
              if: {
                properties: { distribution: { const: 'pert' } },
              },
              then: {
                properties: {
                  min: { type: 'number', minimum: 0 },
                  most: {
                    type: 'number',
                    exclusiveMinimum: { $data: '1/min' },
                    exclusiveMaximum: { $data: '1/max' },
                  },
                  max: {
                    type: 'number',
                    exclusiveMinimum: { $data: '1/most' },
                  },
                },
              },
            },
            {
              if: {
                properties: { distribution: { const: 'uniform' } },
              },
              then: {
                properties: {
                  min: { type: 'number', minimum: 0 },
                  max: { type: 'number', exclusiveMinimum: { $data: '1/min' } },
                },
              },
            },
            {
              if: {
                properties: { distribution: { const: 'spike' } },
              },
              then: {
                properties: {
                  most: { type: 'number', minimum: 0 },
                },
              },
            },
          ],
        },

        else: {
          allOf: [
            {
              if: {
                properties: { distribution: { const: 'pert' } },
              },
              then: {
                properties: {
                  min: { type: 'number', exclusiveMinimum: 0 },
                  most: {
                    type: 'number',
                    exclusiveMinimum: { $data: '1/min' },
                    exclusiveMaximum: { $data: '1/max' },
                  },
                  max: {
                    type: 'number',
                    exclusiveMinimum: { $data: '1/most' },
                  },
                },
              },
            },
            {
              if: {
                properties: { distribution: { const: 'uniform' } },
              },
              then: {
                properties: {
                  min: { type: 'number', exclusiveMinimum: 0 },
                  max: { type: 'number', exclusiveMinimum: { $data: '1/min' } },
                },
              },
            },
            {
              if: {
                properties: { distribution: { const: 'spike' } },
              },
              then: {
                properties: {
                  most: { type: 'number', exclusiveMinimum: 0 },
                },
              },
            },
            {
              properties: {
                from: {
                  type: 'string',
                  not: { const: { $data: '1/to' } },
                  errorMessage:
                    translations.activityModel_theToValueMustNotBeEqualToTheFromValue,
                },
                to: {
                  type: 'string',
                  not: { const: { $data: '1/from' } },
                  errorMessage:
                    translations.activityModel_theToValueMustNotBeEqualToTheFromValue,
                },
              },
            },
          ],
        },
      },
    ],
  },
};
