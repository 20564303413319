import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const campaignSchema = {
  type: 'object',
  properties: {
    campaignId: { type: 'string' },
    companyId: { type: 'string' },
    name: {
      ...nonEmptyStringSchema,
    },
    isConcept: { type: 'boolean' },
  },
  required: ['name'],
  additionalProperties: false,
};
