import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const designsSchema = {
  type: 'object',
  properties: {
    name: {
      ...nonEmptyStringSchema,
    },
    wellbore: { type: 'string' },
    projectWellboreId: { type: 'string' },
    projectDesignId: { type: ['string', 'null'] },
  },
  required: ['name'],
  additionalProperties: false,
};
