import { wellConfiguration, drillingUnit } from '~src/enums/settings';

export const wellDetailsSchema = {
  type: 'object',
  properties: {
    wellDetailsId: { type: 'string' },
    projectId: { type: 'string' },
    datasetId: { type: 'number' },
    wellConfiguration: {
      type: 'string',
      enum: Object.values(wellConfiguration),
    },
    drillingUnit: {
      type: 'string',
      enum: Object.values(drillingUnit),
    },
    waterDepth: {
      type: 'number',
    },
    airGap: {
      type: 'number',
      minimum: 0,
    },
    wellheadDepth: {
      type: 'number',
      minimum: 0,
    },
    location: {
      type: 'object',
    },
    lastSynced: {
      type: 'string',
    },
    mobilization: {
      type: 'boolean',
    },
    rigMove: {
      type: 'boolean',
    },
    interface: {
      type: 'boolean',
    },
    demobilization: {
      type: 'boolean',
    },
    lowerCompletion: {
      type: 'boolean',
    },
    middleCompletion: {
      type: 'boolean',
    },
    upperCompletion: {
      type: 'boolean',
    },
    xmt: {
      type: 'boolean',
    },
    isGenerateFromSchematic: {
      type: 'boolean',
    },
  },
  additionalProperties: false,
  if: {
    properties: {
      wellConfiguration: {
        const: wellConfiguration.OFFSHORE,
      },
    },
  },
  then: {
    properties: {
      waterDepth: { exclusiveMinimum: 0 },
    },
  },
};
