import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const compareSchema = {
  type: 'object',
  properties: {
    compareId: { type: 'string' },
    name: { ...nonEmptyStringSchema },
    isConcept: { type: 'boolean' },
    active: { type: 'boolean' },
    isPublic: { type: ['string', 'boolean'] },
    subjectId: { type: ['string', 'null'] },
    designs: {
      anyOf: [
        {
          type: 'array',
          items: {
            type: 'object',
          },
        },
        { type: 'null' },
      ],
    },
  },
  required: ['compareId', 'name'],
  additionalProperties: false,
};
