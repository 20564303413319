import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const codesListsSchema = {
  type: 'object',
  properties: {
    name: {
      ...nonEmptyStringSchema,
    },
    codesListId: { type: 'string' },
    active: { type: 'boolean' },
    default: { type: 'boolean' },
  },
  required: ['name'],
  additionalProperties: false,
};
