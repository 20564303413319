import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '~store/middleware/api/api';
import {
  IPriceBookItem,
  IPriceBookState,
} from '~src/common/interfaces/price-book.interfaces';
import { CostType } from '~src/enums/cost-setup';
import { toast } from '@oliasoft-open-source/react-ui-library';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import type { TAppDispatch, TGetState } from '~src/store/store-types';

export const initialPriceBook: IPriceBookItem = {
  priceBookId: null,
  companyId: null,
  name: 'Name',
  vendor: 'Vendor',
  price: 100,
  currency: 'USD',
  priceType: CostType.DayRate,
};

const initialState: IPriceBookState = {
  isAdding: false,
  isFetching: false,
  isUpdating: false,
  priceBookItems: [],
};

const priceBook = createSlice({
  name: 'priceBook',
  initialState,
  reducers: {
    addPriceBookRequested: (priceBook) => {
      priceBook.isAdding = true;
    },
    addPriceBookReceived: (
      priceBook,
      action: PayloadAction<{ priceBook: IPriceBookItem }>,
    ) => {
      priceBook.isAdding = false;
      priceBook.priceBookItems.push(action.payload.priceBook);
    },
    addPriceBookRequestedFailed: (priceBook) => {
      priceBook.isAdding = false;
    },
    getPriceBookRequested: (priceBook) => {
      priceBook.isFetching = true;
    },
    getPriceBookReceived: (
      priceBook,
      action: PayloadAction<{ priceBooks: IPriceBookItem[] }>,
    ) => {
      priceBook.priceBookItems = action.payload.priceBooks;
      priceBook.isFetching = false;
    },
    getPriceBookRequestedFailed: (priceBook) => {
      priceBook.isFetching = false;
    },
    priceBookCellValueUpdated: (
      priceBook,
      action: PayloadAction<{
        id: string;
        value: string;
        field: keyof IPriceBookItem;
      }>,
    ) => {
      const { id, value, field } = action.payload;
      const priceBookIndex = priceBook.priceBookItems.findIndex(
        (item) => item.priceBookId === id,
      );
      if (priceBookIndex !== -1) {
        priceBook.priceBookItems[priceBookIndex] = {
          ...priceBook.priceBookItems[priceBookIndex],
          [field]: value,
        };
      }
    },
    updatePriceBookRequested: (priceBook) => {
      priceBook.isUpdating = true;
    },
    updatePriceBookReceived: (priceBook) => {
      priceBook.isUpdating = false;
    },
    updatePriceBookFailed: (priceBook) => {
      priceBook.isUpdating = false;
    },
    priceBookRemoved: (priceBook, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      priceBook.priceBookItems = priceBook.priceBookItems.filter(
        (item) => item.priceBookId !== id,
      );
    },
  },
});

export const {
  addPriceBookRequested,
  addPriceBookReceived,
  addPriceBookRequestedFailed,
  getPriceBookRequested,
  getPriceBookReceived,
  getPriceBookRequestedFailed,
  priceBookCellValueUpdated,
  updatePriceBookRequested,
  updatePriceBookReceived,
  updatePriceBookFailed,
  priceBookRemoved,
} = priceBook.actions;

export default priceBook.reducer;

/**
 * Get Price Book
 *
 */
export const getPriceBook = () => {
  return apiCallBegan({
    url: '/api/price-book',
    method: 'GET',
    onStart: getPriceBookRequested.type,
    onSuccess: getPriceBookReceived.type,
    onError: getPriceBookRequestedFailed.type,
  });
};

/**
 * Add Price Book
 *
 */
export const addPriceBook = (data: IPriceBookItem) => {
  return apiCallBegan({
    url: '/api/price-book',
    method: 'POST',
    data,
    onStart: addPriceBookRequested.type,
    onSuccess: addPriceBookReceived.type,
    onError: addPriceBookRequestedFailed.type,
  });
};

/**
 * Update Price Book
 *
 * @param {string} id
 * @param {import('~src/common/interfaces/price-book.interfaces').PriceBookItem} data
 */
export const updatePriceBook =
  (id: string, data: IPriceBookItem) =>
  (dispatch: TAppDispatch, getState: TGetState) => {
    const state = getState();
    const items = state.entities.priceBook.priceBookItems;

    if (checkForDuplicates(items)) {
      toast({
        message: {
          type: 'Error',
          icon: true,
          content: i18n.t(
            translations.priceBook_priceBookCantHaveDuplicateItems,
          ),
        },
      });
      return;
    }

    dispatch(
      apiCallBegan({
        url: `/api/price-book/${id}`,
        method: 'PUT',
        data,
        onStart: updatePriceBookRequested.type,
        onSuccess: updatePriceBookReceived.type,
        onError: updatePriceBookFailed.type,
      }),
    );
  };

/**
 * Remove Price Book
 *
 * @param {string} id
 */
export const removePriceBookItem = (id: string) => {
  return apiCallBegan({
    url: `/api/price-book/${id}`,
    method: 'DELETE',
    onSuccess: priceBookRemoved.type,
  });
};

export const checkForDuplicates = (list: IPriceBookItem[]) => {
  const seen = new Set();
  for (const item of list) {
    const identifier = `${item.name}:${item.vendor}`;
    if (seen.has(identifier)) {
      return true;
    }
    seen.add(identifier);
  }
  return false;
};
