import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Table,
  Text,
  Spacer,
  Loader,
  Spinner,
} from '@oliasoft-open-source/react-ui-library';
import { getResolver } from '~src/validation/resolver';
import { compareEstimatesSchema } from '~schemas/compare-estimates/compare-estimates.schema';
import { runEstimateCompare } from '~store/entities/compare-estimates/compare-estimates';
import translations from '~src/internationalisation/translation-map.json';
import { pick } from 'lodash';
import { HierarchyType } from '~src/enums/compare-estimates';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const CompareEstimatesModal = ({
  setModalVisible,
  isFetching,
  isAdding,
  estimatesList,
  runEstimateCompare,
  activeCompare,
  filteredEstimates,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const [filters, setFilters] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    defaultValues: {
      estimates: filteredEstimates || [],
    },
    resolver: getResolver(compareEstimatesSchema),
    mode: 'onChange',
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'estimates',
  });

  const watchEstimates = watch('estimates');

  const onSubmit = handleSubmit(async (data) => {
    const { estimates } = data;
    runEstimateCompare({
      name: activeCompare.name,
      estimates,
      companyId: company,
      compareId: activeCompare.compareId,
      designs: null,
    });
  });
  const onClose = () => setModalVisible(false);

  const handleOnChangeInput = (ev, identifier) => {
    setSelectedPage(1);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [identifier]: ev.target.value,
    }));
  };

  const headerLabels = [
    { label: t(translations.estimate), identifier: HierarchyType.Name },
    { label: t(translations.well), identifier: HierarchyType.WellName },
    { label: t(translations.wellbore), identifier: HierarchyType.WellboreName },
    { label: t(translations.site), identifier: HierarchyType.SiteName },
    { label: t(translations.country), identifier: HierarchyType.CountryName },
  ];

  const headerRow = headerLabels.map(({ label, identifier }) => ({
    value: label,
    label: identifier,
    width: 150,
  }));

  const headerFilter = headerLabels.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return {
      key: identifier,
      value: filterValue,
      type: 'Input',
      placeholder: t(translations.search),
      onChange: (ev) => handleOnChangeInput(ev, identifier),
    };
  });

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = estimatesList.filter((design) =>
    Object.keys(filters).every(
      (key) =>
        filters[key] === '' ||
        design[key]?.toLowerCase().includes(filters[key].toLowerCase()),
    ),
  );

  const rows = [
    ...filteredData.map((estimate) => {
      const isChecked = watchEstimates.some(
        (item) => item.projectId === estimate.projectId,
      );
      const rowCells = [
        {
          type: 'CheckBox',
          checked: isChecked,
          onChange: () => {
            if (!isChecked) {
              append({ projectId: estimate.projectId });
            } else {
              remove(
                watchEstimates.findIndex(
                  (item) => item.projectId === estimate.projectId,
                ),
              );
            }
          },
        },
        ...Object.entries(
          pick(estimate, [
            HierarchyType.Name,
            HierarchyType.WellName,
            HierarchyType.WellboreName,
            HierarchyType.SiteName,
            HierarchyType.CountryName,
          ]),
        ).map(([key, value]) => ({
          key,
          value,
        })),
      ];
      return { cells: rowCells };
    }),
  ];

  const filteredRows = rows.slice(startIndex, endIndex);

  const table = {
    fixedWidth: 'auto',
    headers: [{ cells: [{}, ...headerRow] }, { cells: [{}, ...headerFilter] }],
    rows: filteredRows,
    footer: {
      pagination: {
        rowCount: rows.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt) => setRowsPerPage(Number(evt.target.value)),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.compare_compareForecastEstimates),
          width: 'auto',
          content: (
            <div>
              {isFetching && (
                <Loader text={t(translations.fetching)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              {errors.estimates && (
                <>
                  <Text error>
                    {t(translations.compare_selectAtLeastTwoEstimates)}
                  </Text>
                  <Spacer />
                </>
              )}
              <Table table={table} />
            </div>
          ),
          footer: (
            <>
              <Button
                label={
                  watchEstimates.length > 0
                    ? `${t(translations.confirmSelection)} (${
                        watchEstimates.length
                      })`
                    : t(translations.confirmSelection)
                }
                colored
                onClick={() => {
                  setValue('name', activeCompare.name);
                  onSubmit();
                }}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { isFetching, isAdding, estimatesList } = entities.compareEstimates;

  return {
    isFetching,
    isAdding,
    estimatesList,
  };
};

const mapDispatchToProps = { runEstimateCompare };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CompareEstimatesModal),
  { isModal: true },
);

export { Container as CompareEstimatesModal };
