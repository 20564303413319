import {
  wellConfiguration,
  drillingUnit,
  depthInequality,
} from '~src/enums/settings';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const highLevelInputsSchema = {
  type: 'object',
  properties: {
    name: { ...nonEmptyStringSchema },
    wellConfiguration: {
      type: 'string',
      enum: Object.values(wellConfiguration),
    },
    drillingUnit: {
      type: 'string',
      enum: Object.values(drillingUnit),
    },
    waterDepthInequality: {
      type: 'string',
      enum: Object.values(depthInequality),
    },
    waterDepth: {
      type: 'number',
    },
    wellheadDepthInequality: {
      type: 'string',
      enum: Object.values(depthInequality),
    },
    wellheadDepth: {
      type: 'number',
    },
    performDst: {
      type: 'boolean',
    },
    runCompletion: {
      type: 'boolean',
    },
    performPa: {
      type: 'boolean',
    },
  },
  required: ['name'],
  additionalProperties: false,
};
