import React from 'react';
import {
  Card,
  Flex,
  Spacer,
  Text,
  Icon,
  Badge,
} from '@oliasoft-open-source/react-ui-library';
import { pageStatuses } from '~src/enums/projects';

const iconColors = {
  [pageStatuses.ACTIVE]: 'var(--color-text-primary)',
  [pageStatuses.FINISHED]: 'var(--color-text-success)',
  [pageStatuses.DISABLED]: 'var(--color-text-muted)',
};

export const PageCard = ({ name, icon, status, onClick = () => {} }) => {
  return (
    <div onClick={onClick} className="page-card">
      <Card>
        <Flex direction="column" alignItems="center">
          <Icon icon={icon} size={40} color={iconColors[status]} />
          <Spacer />
          <Text center bold muted={status === pageStatuses.DISABLED}>
            {name}
          </Text>
        </Flex>
      </Card>
      <div className="page-card-badge">
        <Badge
          title={status === pageStatuses.FINISHED ? '√' : null}
          color="var(--color-text-success)"
        />
      </div>
    </div>
  );
};
