import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Column, Drawer } from '@oliasoft-open-source/react-ui-library';
import {
  updateTemplate as updateUserTemplate,
  showOperationsFormUpdated as showUserOperationsFormUpdated,
  showDeleteOperationsModalUpdated as showUserDeleteOperationsModalUpdated,
  showDeleteActivitiesModalUpdated as showUserDeleteActivitiesModalUpdated,
  showImportOperationModalUpdated as showImportUserOperationModalUpdated,
  closeActivitiesForm as closeUserActivitiesForm,
  sectionTypeSelected as userSectionTypeSelected,
  operationSelected as userOperationSelected,
  addInitialOperation as addUserInitialOperation,
  addInitialActivity as addInitialUserActivity,
  reorderActivities as reorderUserActivities,
  showActivitiesForm as showUserActivitiesForm,
  removeActivity as removeUserActivity,
  removeOperation as removeUserOperation,
  updateOperation as updateUserOperation,
  updateActivity as updateUserActivity,
  importOperations as importUserOperations,
  reorderOperationsInSettings as reorderUserOperationsInSettings,
  showAddOperationFromSectionsDataModalUpdated as showUserAddOperationFromSectionsDataModalUpdated,
  addOperationFromSectionsData as addUserOperationFromSectionsData,
  showAddActivityFromSectionsDataModalUpdated as showUserAddActivityFromSectionsDataModalUpdated,
  addActivityFromSectionsData as addUserActivityFromSectionsData,
} from '~store/entities/user-settings/user-settings';

import {
  updateTemplate as updateCompanyTemplate,
  showOperationsFormUpdated as showCompanyOperationsFormUpdated,
  showDeleteOperationsModalUpdated as showCompanyDeleteOperationsModalUpdated,
  showDeleteActivitiesModalUpdated as showCompanyDeleteActivitiesModalUpdated,
  showImportOperationModalUpdated as showImportCompanyOperationModalUpdated,
  closeActivitiesForm as closeCompanyActivitiesForm,
  sectionTypeSelected as companySectionTypeSelected,
  operationSelected as companyOperationSelected,
  addInitialOperation as addCompanyInitialOperation,
  addInitialActivity as addInitialCompanyActivity,
  reorderActivities as reorderCompanyActivities,
  showActivitiesForm as showCompanyActivitiesForm,
  removeActivity as removeCompanyActivity,
  removeOperation as removeCompanyOperation,
  updateOperation as updateCompanyOperation,
  updateActivity as updateCompanyActivity,
  importOperations as importCompanyOperations,
  reorderOperationsInSettings as reorderCompanyOperationsInSettings,
  showAddOperationFromSectionsDataModalUpdated as showCompanyAddOperationFromSectionsDataModalUpdated,
  addOperationFromSectionsData as addCompanyOperationFromSectionsData,
  showAddActivityFromSectionsDataModalUpdated as showCompanyAddActivityFromSectionsDataModalUpdated,
  addActivityFromSectionsData as addCompanyActivityFromSectionsData,
} from '~store/entities/company-settings/company-settings';
import { linkFields, settingEntity } from '~src/enums/settings';
import { SectionsList } from '~views/settings/templates/activity-model/sections.list';
import { OperationsList } from '~views/settings/templates/activity-model/operations.list';
import { ActivitiesList } from '~views/settings/templates/activity-model/activities.list';
import { OperationModal } from '~views/settings/templates/activity-model/operation.modal';
import { OperationDeleteModal } from '~views/settings/templates/activity-model/operation-delete.modal';
import { ActivityDeleteModal } from '~views/settings/templates/activity-model/activity-delete.modal';
import { ActivityModal } from '~views/settings/templates/activity-model/activity.modal';
import { ImportOperationModal } from '~views/settings/templates/activity-model/import-operation.modal';
import { AddOperationFromSectionsDataModal } from '~views/settings/templates/activity-model/add-operation-from-sections-data.modal';
import { AddActivityFromSectionsDataModal } from '~views/settings/templates/activity-model/add-activity-from-sections-data.modal';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import styles from '../templates.module.less';

const ActivityModel = ({
  entitySettings,
  settings,
  activeSection,
  activityModel,
  activeOperation,
  activeActivity,
  unitSettings,
  showOperationsForm,
  showActivityForm,
  showOperationsFormUpdated,
  showDeleteOperationsModal,
  showDeleteActivitiesModal,
  showImportOperationModal,
  showAddOperationFromSectionsDataModal,
  showAddActivityFromSectionsDataModal,
  showDeleteOperationsModalUpdated,
  showDeleteActivitiesModalUpdated,
  showImportOperationModalUpdated,
  showAddOperationFromSectionsDataModalUpdated,
  showAddActivityFromSectionsDataModalUpdated,
  closeActivitiesForm,
  selectedId,
  predefinedOptions,
  activeTemplate,
  sectionTypes,
  sectionTypeSelected,
  operationSelected,
  addInitialOperation,
  addInitialActivity,
  reorderActivities,
  showActivitiesForm,
  removeActivity,
  removeOperation,
  updateOperation,
  updateActivity,
  importOperations,
  reorderOperationsInSettings,
  addOperationFromSectionsData,
  addActivityFromSectionsData,
}) => {
  const [sectionDrawerWidth, setSectionDrawerWidth] = useState(260);
  const [operationDrawerWidth, setOperationDrawerWidth] = useState(300);
  const { isAdding } = entitySettings;

  return (
    <>
      <div className={styles.activityModelTabWrapper}>
        <Row height="100%" spacing={0}>
          <Drawer
            width={sectionDrawerWidth}
            closedWidth={50}
            open
            button
            border
            onResize={(width) => setSectionDrawerWidth(width)}
          >
            <Column scroll width="250px" borderRight spacing={0}>
              <SectionsList
                sectionTypes={sectionTypes}
                sectionTypeSelected={sectionTypeSelected}
              />
            </Column>
          </Drawer>
          {activeSection && (
            <Drawer
              width={operationDrawerWidth}
              closedWidth={50}
              open
              button
              border
              onResize={(width) => setOperationDrawerWidth(width)}
            >
              <Column scroll borderRight width="350px" spacing={0}>
                <OperationsList
                  settings={settings}
                  reorderOperationsInSettings={reorderOperationsInSettings}
                  entitySettings={entitySettings}
                  activityModel={activityModel}
                  operationSelected={operationSelected}
                  addInitialOperation={addInitialOperation}
                  setOperationModalVisible={showOperationsFormUpdated}
                  showDeleteOperationsModalUpdated={
                    showDeleteOperationsModalUpdated
                  }
                  showImportOperationModalUpdated={
                    showImportOperationModalUpdated
                  }
                  showAddOperationFromSectionsDataModalUpdated={
                    showAddOperationFromSectionsDataModalUpdated
                  }
                />
              </Column>
            </Drawer>
          )}
          {activeOperation && (
            <Column scroll>
              <ActivitiesList
                entitySettings={entitySettings}
                activeOperation={activeOperation}
                showDeleteActivitiesModalUpdated={
                  showDeleteActivitiesModalUpdated
                }
                addInitialActivity={addInitialActivity}
                reorderActivities={reorderActivities}
                showActivitiesForm={showActivitiesForm}
                showAddActivityFromSectionsDataModalUpdated={
                  showAddActivityFromSectionsDataModalUpdated
                }
              />
            </Column>
          )}
        </Row>
      </div>
      {showOperationsForm && (
        <OperationModal
          settings={settings}
          setModalVisible={showOperationsFormUpdated}
          operation={activeOperation}
          unitSettings={unitSettings}
          predefinedOptions={predefinedOptions}
          updateOperation={updateOperation}
          isAdding={isAdding}
          activeSection={activeSection}
        />
      )}
      {showActivityForm && (
        <ActivityModal
          settings={settings}
          closeActivitiesForm={closeActivitiesForm}
          operation={activeOperation}
          activity={activeActivity}
          unitSettings={unitSettings}
          predefinedOptions={predefinedOptions}
          updateActivity={updateActivity}
          isAdding={isAdding}
          activeOperation={activeOperation}
        />
      )}
      {showDeleteOperationsModal && (
        <OperationDeleteModal
          settings={settings}
          setModalVisible={showDeleteOperationsModalUpdated}
          selectedId={selectedId}
          removeOperation={removeOperation}
        />
      )}
      {showDeleteActivitiesModal && (
        <ActivityDeleteModal
          settings={settings}
          setModalVisible={showDeleteActivitiesModalUpdated}
          selectedId={selectedId}
          removeActivity={removeActivity}
        />
      )}
      {showImportOperationModal && (
        <ImportOperationModal
          settings={settings}
          activeTemplate={activeTemplate}
          showImportOperationModalUpdated={showImportOperationModalUpdated}
          activeSection={activeSection}
          entitySettings={entitySettings}
          importOperations={importOperations}
        />
      )}
      {showAddOperationFromSectionsDataModal && (
        <AddOperationFromSectionsDataModal
          settings={settings}
          activeSection={activeSection}
          setModalVisible={showAddOperationFromSectionsDataModalUpdated}
          unitSettings={unitSettings}
          predefinedOptions={predefinedOptions}
          addOperationFromSectionsData={addOperationFromSectionsData}
          isAdding={isAdding}
        />
      )}
      {showAddActivityFromSectionsDataModal && (
        <AddActivityFromSectionsDataModal
          settings={settings}
          operation={activeOperation}
          setModalVisible={showAddActivityFromSectionsDataModalUpdated}
          activity={activeActivity}
          unitSettings={unitSettings}
          predefinedOptions={predefinedOptions}
          addActivityFromSectionsData={addActivityFromSectionsData}
          isAdding={isAdding}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ entities }, { entitySettings }) => {
  const {
    selectedId,
    sectionTypes,
    settings,
    showOperationsForm,
    showActivityForm,
    showDeleteOperationsModal,
    showDeleteActivitiesModal,
    showImportOperationModal,
    showAddOperationFromSectionsDataModal,
    showAddActivityFromSectionsDataModal,
  } = entitySettings;

  const activeSection = sectionTypes.find((item) => item.active);
  const templateIndex = settings.templates.findIndex((item) => item.active);
  const operations = settings.templates[templateIndex].activityModel;
  const activityModel = activeSection
    ? operations.filter((item) => item.sectionType === activeSection.sectionId)
    : [];
  const activeOperation = activityModel.find((item) => item.active);
  const activeActivity =
    activeOperation?.activities &&
    activeOperation.activities.find((item) => item.active);

  const predefinedOptions = Object.values(linkFields).map((field) => ({
    label: field,
    value: field,
  }));

  return {
    activeSection,
    activityModel,
    activeOperation,
    activeActivity,
    showOperationsForm,
    showActivityForm,
    showDeleteOperationsModal,
    showDeleteActivitiesModal,
    showImportOperationModal,
    unitSettings: entities.userSettings.settings.units,
    settings,
    selectedId,
    predefinedOptions,
    activeTemplate: templateIndex,
    sectionTypes,
    showAddOperationFromSectionsDataModal,
    showAddActivityFromSectionsDataModal,
  };
};

const mapDispatchToProps = (dispatch, { entity }) =>
  bindActionCreators(
    {
      updateTemplate:
        entity === settingEntity.USER
          ? updateUserTemplate
          : updateCompanyTemplate,
      showOperationsFormUpdated:
        entity === settingEntity.USER
          ? showUserOperationsFormUpdated
          : showCompanyOperationsFormUpdated,
      showDeleteOperationsModalUpdated:
        entity === settingEntity.USER
          ? showUserDeleteOperationsModalUpdated
          : showCompanyDeleteOperationsModalUpdated,
      showDeleteActivitiesModalUpdated:
        entity === settingEntity.USER
          ? showUserDeleteActivitiesModalUpdated
          : showCompanyDeleteActivitiesModalUpdated,
      showImportOperationModalUpdated:
        entity === settingEntity.USER
          ? showImportUserOperationModalUpdated
          : showImportCompanyOperationModalUpdated,
      closeActivitiesForm:
        entity === settingEntity.USER
          ? closeUserActivitiesForm
          : closeCompanyActivitiesForm,
      sectionTypeSelected:
        entity === settingEntity.USER
          ? userSectionTypeSelected
          : companySectionTypeSelected,
      operationSelected:
        entity === settingEntity.USER
          ? userOperationSelected
          : companyOperationSelected,
      addInitialOperation:
        entity === settingEntity.USER
          ? addUserInitialOperation
          : addCompanyInitialOperation,
      addInitialActivity:
        entity === settingEntity.USER
          ? addInitialUserActivity
          : addInitialCompanyActivity,
      reorderActivities:
        entity === settingEntity.USER
          ? reorderUserActivities
          : reorderCompanyActivities,
      showActivitiesForm:
        entity === settingEntity.USER
          ? showUserActivitiesForm
          : showCompanyActivitiesForm,
      removeActivity:
        entity === settingEntity.USER
          ? removeUserActivity
          : removeCompanyActivity,
      removeOperation:
        entity === settingEntity.USER
          ? removeUserOperation
          : removeCompanyOperation,
      updateOperation:
        entity === settingEntity.USER
          ? updateUserOperation
          : updateCompanyOperation,
      updateActivity:
        entity === settingEntity.USER
          ? updateUserActivity
          : updateCompanyActivity,
      importOperations:
        entity === settingEntity.USER
          ? importUserOperations
          : importCompanyOperations,
      reorderOperationsInSettings:
        entity === settingEntity.USER
          ? reorderUserOperationsInSettings
          : reorderCompanyOperationsInSettings,
      showAddOperationFromSectionsDataModalUpdated:
        entity === settingEntity.USER
          ? showUserAddOperationFromSectionsDataModalUpdated
          : showCompanyAddOperationFromSectionsDataModalUpdated,
      addOperationFromSectionsData:
        entity === settingEntity.USER
          ? addUserOperationFromSectionsData
          : addCompanyOperationFromSectionsData,
      showAddActivityFromSectionsDataModalUpdated:
        entity === settingEntity.USER
          ? showUserAddActivityFromSectionsDataModalUpdated
          : showCompanyAddActivityFromSectionsDataModalUpdated,
      addActivityFromSectionsData:
        entity === settingEntity.USER
          ? addUserActivityFromSectionsData
          : addCompanyActivityFromSectionsData,
    },
    dispatch,
  );

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ActivityModel),
);

export { Container as ActivityModel };
