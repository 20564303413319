import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import {
  Input,
  InputGroupAddon,
  InputGroup,
  Tooltip,
  Button,
  Menu,
  Text,
  Spacer,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const PredefinedOptionsMenuState = {
  Custom: 'custom',
  Predefined: 'predefined',
};

const RawUnitInput = ({
  name,
  control,
  unit,
  errors,
  error = null,
  warning = null,
  predefinedOptions,
  disabled = false,
  width,
}) => {
  const { t } = useTranslation();
  const { field } = useController({
    name,
    control,
  });
  const [predefinedOptionsMenuState, setPredefinedOptionsMenuState] = useState(
    PredefinedOptionsMenuState.Custom,
  );

  let sectionsPredefinedMenu = [];
  const { onChange, ...props } = field;
  const foundPredefinedMenuValue =
    predefinedOptions &&
    predefinedOptions.find((el) => el.value === props.value);

  useEffect(() => {
    if (predefinedOptions) {
      if (foundPredefinedMenuValue) {
        setPredefinedOptionsMenuState(PredefinedOptionsMenuState.Predefined);
      } else {
        setPredefinedOptionsMenuState(PredefinedOptionsMenuState.Custom);
      }
    }
  }, [props.value]);

  if (predefinedOptions) {
    sectionsPredefinedMenu = [
      {
        type: 'Option',
        inline: true,
        onClick: () =>
          setPredefinedOptionsMenuState(PredefinedOptionsMenuState.Custom),
        label: 'Custom',
        description: 'Custom',
        selected:
          predefinedOptionsMenuState === PredefinedOptionsMenuState.Custom,
      },
    ].concat(
      predefinedOptions.map((el) => {
        return {
          type: 'Option',
          inline: true,
          onClick: () => {
            setPredefinedOptionsMenuState(
              PredefinedOptionsMenuState.Predefined,
            );
            onChange(el.value);
          },
          label: (
            <>
              <Text>{el.label}</Text>
              <Spacer width="var(--padding-sm)" height="0" />
            </>
          ),
          description: el.value,
          selected: el.value === props.value,
        };
      }),
    );
  }

  return (
    <InputGroup width={width}>
      {predefinedOptions && (
        <Menu
          colored
          maxHeight={380}
          groupOrder="first"
          disabled={disabled}
          menu={{
            trigger: 'Component',
            component: (
              <Tooltip
                text={
                  predefinedOptionsMenuState ===
                  PredefinedOptionsMenuState.Predefined
                    ? foundPredefinedMenuValue?.label
                    : PredefinedOptionsMenuState.Custom
                }
              >
                <Button
                  groupOrder="first"
                  active={
                    predefinedOptionsMenuState ===
                    PredefinedOptionsMenuState.Predefined
                  }
                  icon={
                    predefinedOptionsMenuState ===
                    PredefinedOptionsMenuState.Predefined
                      ? 'link'
                      : 'unlink'
                  }
                />
              </Tooltip>
            ),
            sections: sectionsPredefinedMenu,
          }}
        />
      )}

      <Input
        {...props}
        onChange={(e) => {
          const { value } = e.target;
          const replaceComasToDotValue = value.replace(/,/, '.');
          if (
            replaceComasToDotValue.endsWith('.') ||
            (replaceComasToDotValue.includes('.') &&
              replaceComasToDotValue.endsWith('0'))
          ) {
            onChange(replaceComasToDotValue);
          } else {
            const numberValue = parseFloat(replaceComasToDotValue) || 0;
            onChange(numberValue);
          }
        }}
        error={
          translations[errors[name]] ? t(errors[name]) : errors[name] || error
        }
        warning={warning}
        disabled={
          predefinedOptionsMenuState !== PredefinedOptionsMenuState.Custom ||
          disabled
        }
      />
      <InputGroupAddon>{unit}</InputGroupAddon>
    </InputGroup>
  );
};

export const UnitInput = withErrorBoundary(RawUnitInput);
