import translations from '~src/internationalisation/translation-map.json';

export const countriesSchema = {
  type: 'object',
  properties: {
    country: {
      type: 'string',
      errorMessage: translations.projects_countryIsRequired,
    },
  },
  required: ['country'],
  additionalProperties: false,
};
