import { timeUnits, lengthUnits } from '~src/enums/settings';

export const unitsSchema = {
  type: 'object',
  properties: {
    length: { type: 'string', enum: lengthUnits },
    time: { type: 'string', enum: timeUnits },
  },
  required: ['length', 'time'],
  additionalProperties: false,
};
