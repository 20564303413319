import { useState, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  Heading,
  Column,
  Table,
  Button,
  Spacer,
  Flex,
} from '@oliasoft-open-source/react-ui-library';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { validateString } from '~src/validation/common/validate-string';
import { validateNumber } from '~src/validation/common/validate-number';
import { getCurrencies } from '~src/store/entities/company-settings/company-settings';
import {
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
  initialPriceBook,
} from '~src/store/entities/price-book/price-book';
import { getSelectOptions } from '~src/common/lists/lists';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import { IPriceBookItem } from '~src/common/interfaces/price-book.interfaces';
import { debounce, noop } from 'lodash';
import { autoSaveWait } from '~src/config/config';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { toNum } from '@oliasoft-open-source/units';
import type { TRootState } from '~src/store/store-types';

const PriceBook = ({
  currenciesList,
  priceBook,
  isAdding,
  isUpdating,
  getCurrencies,
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
}: PropsFromRedux) => {
  const { t } = useTranslation();

  const debounceUpdatePriceBook = useRef(
    debounce(updatePriceBook, autoSaveWait),
  );

  const [filters, setFilters] = useState<Partial<IPriceBookItem>>({});

  useEffect(() => {
    getCurrencies();
    getPriceBook();
  }, []);

  const typeList = [
    { label: t(translations.costSetup_dayRate), value: CostType.DayRate },
    { label: t(translations.costSetup_lumpSum), value: CostType.LumpSum },
    {
      label: `${t(translations.costSetup_quantity)}: ${t(
        translations.costSetup_length,
      )}`,
      value: `${CostType.Quantity}-${QuantityType.Length}`,
    },
    {
      label: `${t(translations.costSetup_quantity)}: ${t(
        translations.costSetup_volume,
      )}`,
      value: `${CostType.Quantity}-${QuantityType.Volume}`,
    },
  ];

  const onAddPriceBook = () => addPriceBook(initialPriceBook);

  const onChangePriceBookList = (
    priceBook: IPriceBookItem,
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    name: string,
  ) => {
    const newValue = name === 'price' ? toNum(ev.target.value) : ev.target.value;
    priceBookCellValueUpdated({
      id: priceBook.priceBookId as string,
      value: newValue,
      field: name as keyof IPriceBookItem,
    });

    if (newValue) {
      debounceUpdatePriceBook.current(priceBook.priceBookId as string, {
        ...priceBook,
        [name]: newValue,
      });
    }
  };

  const onChangeFilter = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFilters((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
    }));
  };

  const filteredPriceBookList = priceBook.filter((item: IPriceBookItem) =>
    Object.keys(filters).every((key) => {
      const filterValue = filters[key as keyof IPriceBookItem];
      if (filterValue === undefined || filterValue === '') return true;

      const itemValue = item[key as keyof IPriceBookItem];

      if (typeof itemValue === 'string' && typeof filterValue === 'string') {
        return itemValue.toLowerCase().startsWith(filterValue.toLowerCase());
      }

      return itemValue === filterValue;
    }),
  );

  const fieldConfigs = [
    { name: 'name', type: 'Input', errorFn: validateString },
    { name: 'vendor', type: 'Input', errorFn: validateString },
    {
      name: 'price',
      type: 'Input',
      errorFn: (val: string) => validateNumber(+val),
    },
    {
      name: 'currency',
      type: 'Select',
      options: currenciesList,
      autoLayerWidth: true,
    },
    {
      name: 'priceType',
      type: 'Select',
      options: typeList,
      autoLayerWidth: true,
    },
  ];

  const rows = filteredPriceBookList?.map((priceBook: IPriceBookItem) => ({
    cells: fieldConfigs.map(
      ({ name, type, options, autoLayerWidth, errorFn }) => ({
        name,
        type,
        value: priceBook[name as keyof IPriceBookItem],
        ...(errorFn && {
          error: errorFn(priceBook[name as keyof IPriceBookItem] as string),
        }),
        ...(options && { options }),
        ...(autoLayerWidth && { autoLayerWidth }),
        onChange: (
          ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        ) => {
          onChangePriceBookList(priceBook, ev, name);
        },
      }),
    ),
    actions: [
      {
        label: 'Delete',
        icon: 'minus',
        onClick: () => removePriceBookItem(priceBook.priceBookId as string),
      },
    ],
  }));

  const columnWidths = ['45%', '20%', '10%', '14%', '10%'];
  const columnAlignment = ['left', 'left', 'left', 'left', 'left'];

  const headerCells = [
    { value: t(translations.name) },
    { value: t(translations.priceBook_vendor) },
    { value: t(translations.priceBook_price) },
    { value: t(translations.currency) },
    { value: t(translations.priceBook_priceType) },
  ];

  const filterCells = [
    {
      type: 'Input',
      placeholder: t(translations.filter),
      onChange: onChangeFilter,
      name: 'name',
      value: filters?.name ?? '',
    },
    {
      type: 'Input',
      placeholder: t(translations.filter),
      onChange: onChangeFilter,
      name: 'vendor',
      value: filters?.vendor ?? '',
    },
    {},
    {
      type: 'Input',
      placeholder: t(translations.filter),
      onChange: onChangeFilter,
      name: 'currency',
      value: filters?.currency ?? '',
    },
    {
      type: 'Input',
      placeholder: t(translations.filter),
      onChange: onChangeFilter,
      name: 'priceType',
      value: filters?.priceType ?? '',
    },
  ];

  const headerActions = [
    {
      icon: 'add',
      label: 'Add',
      onClick: onAddPriceBook,
      primary: true,
      disabled: isAdding || isUpdating,
    },
  ];

  const tableConfig = {
    columnWidths,
    columnAlignment,
    headers: [
      {
        actions: headerActions,
        cells: headerCells,
      },
      {
        cells: filterCells,
      },
    ],
    rows,
  };

  return (
    <Column padding spacing={0}>
      <Flex justifyContent="space-between" gap>
        <Heading top>{t(translations.priceBook)}</Heading>
        <div>
          <Button
            label={t(translations.priceBook_importFile)}
            onClick={noop}
            small
          />
        </div>
      </Flex>
      <Table table={tableConfig} />
      <Spacer />
    </Column>
  );
};

const mapStateToProps = ({ entities }: TRootState) => {
  const { currenciesList } = entities.companySettings;
  const { priceBookItems, isAdding, isUpdating } = entities.priceBook;
  return {
    currenciesList: getSelectOptions(currenciesList, 'code', 'code'),
    priceBook: priceBookItems,
    isAdding,
    isUpdating,
  };
};

const mapDispatchToProps = {
  getCurrencies,
  addPriceBook,
  getPriceBook,
  priceBookCellValueUpdated,
  updatePriceBook,
  removePriceBookItem,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = withErrorBoundary(connector(PriceBook));

export { Container as PriceBook };
