import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Table,
  Modal,
  Dialog,
  Button,
  Field,
  Tabs,
  Loader,
  Spinner,
  Message,
} from '@oliasoft-open-source/react-ui-library';
import { getResolver } from '~src/validation/resolver';
import { Input, NumberInput } from '~common/form-inputs';
import {
  getProjectSettings,
  saveProjectSettings,
} from '~store/entities/project-settings/project-settings';
import { projectNameUpdated } from '~store/entities/projects/projects';
import { projectSettingsSchema } from '~src/schemas/project-settings/project-settings.schema';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const ProjectSettingsModal = ({
  settings,
  setModalVisible,
  getProjectSettings,
  saveProjectSettings,
  projectNameUpdated,
  additionalCurrencyOptions,
  isFetching,
  isAdding,
}) => {
  const { t } = useTranslation();
  const { project } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [additionalCurrenciesList, setAdditionalCurrenciesList] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      projectName: settings.projectName,
      threadsPerSimulation: settings.threadsPerSimulation,
      primaryCurrency: settings.primaryCurrency,
    },
    resolver: getResolver(projectSettingsSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    getProjectSettings(project);
  }, []);

  useEffect(() => {
    const {
      projectName,
      threadsPerSimulation,
      primaryCurrency,
      additionalCurrencies,
    } = settings;
    setAdditionalCurrenciesList(additionalCurrencies || []);
    reset({ projectName, threadsPerSimulation, primaryCurrency });
  }, [settings]);

  const onClose = () => setModalVisible(false);
  const onSubmit = handleSubmit((data) => {
    projectNameUpdated(data.projectName);
    saveProjectSettings(project, {
      ...data,
      additionalCurrencies: additionalCurrenciesList,
    });
  });

  const onAddCurrency = () =>
    setAdditionalCurrenciesList([
      ...additionalCurrenciesList,
      {
        currency: additionalCurrencyOptions[0]?.value,
        exchangeRate: 100,
      },
    ]);

  const onChangeAdditionalCurrency = (ev, i) => {
    const newElement = [...additionalCurrenciesList];
    if (ev.target.name === 'currency') {
      newElement[i] = {
        ...newElement[i],
        currency: ev.target.value,
      };
    } else if (ev.target.name === 'exchangeRate') {
      newElement[i] = {
        ...newElement[i],
        exchangeRate: ev.target.value,
      };
    }
    setAdditionalCurrenciesList(newElement);
  };

  const onRemoveCurrency = (i) => {
    const newCurriencies = [...additionalCurrenciesList];
    newCurriencies.splice(i, 1);
    setAdditionalCurrenciesList(newCurriencies);
  };

  return (
    <Modal visible centered>
      <Dialog
        dialog={{
          width: '500px',
          heading: t(translations.projects_projectSettings),
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isFetching && (
                <Loader text={t(translations.adding)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <Field label={t(translations.projects_projectName)}>
                  <Input name="projectName" control={control} errors={errors} />
                </Field>
                <Tabs
                  onChange={(ev) => {
                    setActiveTab(ev.target.value);
                  }}
                  value={activeTab}
                  options={[
                    {
                      label: t(
                        translations.projectSettings_monteCarloSimulation,
                      ),
                      value: 0,
                    },
                    // { label: t(translations.currency), value: 1 }, commented due to OW-14010
                  ]}
                />
                {activeTab === 0 ? (
                  <Field
                    label={t(translations.projectSettings_threadsPerSimulation)}
                  >
                    <NumberInput
                      name="threadsPerSimulation"
                      width="135px"
                      control={control}
                      errors={errors}
                    />
                  </Field>
                ) : (
                  <>
                    <Field
                      label={t(translations.projectSettings_primaryCurrency)}
                    >
                      <Message
                        message={{
                          content: '(USD) United States Dollar',
                          type: 'Info',
                          visible: true,
                        }}
                      />
                    </Field>
                    <Table
                      table={{
                        fixedWidth: '100%',
                        columnAlignment: ['left', 'right', 'left'],
                        headers: [
                          {
                            cells: [
                              {
                                value: t(
                                  translations.projectSettings_additionalCurrency,
                                ),
                              },
                              {
                                value: t(
                                  translations.projectSettings_exchangeRate,
                                ),
                                style: { width: '120px' },
                              },
                              { value: '', style: { width: '90px' } },
                            ],
                            actions: [
                              {
                                primary: true,
                                label: t(translations.add),
                                icon: 'add',
                                disabled: additionalCurrenciesList.length >= 10,
                                onClick: () => {
                                  onAddCurrency();
                                },
                              },
                            ],
                          },
                        ],
                        rows: additionalCurrenciesList.map((element, i) => ({
                          cells: [
                            {
                              name: 'currency',
                              type: 'Select',
                              options: additionalCurrencyOptions,
                              value: element.currency,
                              onChange: (ev) => {
                                onChangeAdditionalCurrency(ev, i);
                              },
                              autoLayerWidth: true,
                            },
                            {
                              name: 'exchangeRate',
                              type: 'Input',
                              value: element.exchangeRate,
                              onChange: (ev) => {
                                onChangeAdditionalCurrency(ev, i);
                              },
                            },
                            {
                              value: `= 1 USD`,
                              disabled: true,
                              style: {
                                opacity: '0.5',
                                cursor: 'default',
                              },
                            },
                          ],
                          actions: [
                            {
                              label: 'Delete',
                              icon: 'minus',
                              onClick: () => {
                                onRemoveCurrency(i);
                              },
                            },
                          ],
                        })),
                      }}
                    />
                  </>
                )}
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={t(translations.save)}
                onClick={onSubmit}
                disabled={isAdding}
                colored
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const {
    projectSettings: { currencies, settings, isAdding, isFetching },
  } = entities;
  const additionalCurrencyOptions = currencies
    .filter((item) => item.code !== 'USD')
    .map((item) => ({
      label: `(${item.code}) ${item.name}`,
      value: item.code,
    }));

  return {
    isFetching,
    isAdding,
    currencies,
    settings,
    additionalCurrencyOptions,
  };
};

const mapDispatchToProps = {
  getProjectSettings,
  saveProjectSettings,
  projectNameUpdated,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(ProjectSettingsModal),
  { isModal: true },
);

export { Container as ProjectSettingsModal };
