import { round, unum, isNumeric } from '@oliasoft-open-source/units';
import { linkFields } from '~src/enums/settings';

/**
 * Converts specified elements in the data object from one unit to another.
 *
 * @param {object} data - The data object containing elements to be converted.
 * @param {string[]} elementsToConvert - An array of element names to be converted.
 * @param {string} fromUnit - The unit to convert from.
 * @param {string} toUnit - The unit to convert to.
 * @param {boolean} [roundValue=false] - Whether to round the converted value or not.
 * @returns {object} - The modified data object with converted values.
 */
export const convertInputUnits = (
  data,
  elementsToConvert,
  fromUnit,
  toUnit,
  roundValue = false,
) => {
  const result = { ...data };
  elementsToConvert.forEach((el) => {
    if (data[el]) {
      const isNumericValue = isNumeric(data[el]);
      let value = isNumericValue ? unum(data[el], toUnit, fromUnit) : data[el];
      if (roundValue) {
        value = isNumericValue ? round(value) : data[el];
      }
      result[el] = value;
    }
  });

  return result;
};

/**
 * Converts specified elements in the data object from one unit to another.
 *
 * @param {object} data - The data object containing elements to be converted.
 * @param {string[]} elementsToConvert - An array of element names to be converted.
 * @param {string} fromUnit - The unit to convert from.
 * @param {string} toUnit - The unit to convert to.
 * @param {boolean} [roundValue=false] - Whether to round the converted value or not.
 * @returns {object} - The modified data object with converted values.
 */
export const convertToInputUnits = (
  data,
  elementsToConvert,
  fromUnit,
  toUnit,
  roundValue = false,
) => {
  const result = { ...data };
  elementsToConvert.forEach((el) => {
    if (data[el] || data[el] == 0) {
      const isNumericValue = isNumeric(data[el]);
      let value = isNumericValue
        ? `${unum(data[el], toUnit, fromUnit)}|${toUnit}`
        : data[el];
      if (roundValue) {
        value = isNumericValue ? round(value) : data[el];
      }
      result[el] = value;
    }
  });

  return result;
};

/**
 * Converts specified elements in the data object from one unit to another.
 *
 * @param {object} data - The data object containing elements to be converted.
 * @param {string[]} elementsToConvert - An array of element names to be converted.
 * @param {object} toUnit - The unit to convert to.
 * @param {boolean} [roundValue=false] - Whether to round the converted value or not.
 * @returns {object} - The modified data object with converted values.
 */
export const convertToStorageUnits = (
  data,
  elementsToConvert,
  toUnit,
  roundValue = false,
) => {
  const result = { ...data };
  elementsToConvert.forEach((el) => {
    if (data[el] || data[el] == 0) {
      const isVariable = Object.values(linkFields)?.includes(data[el]);
      let value = isVariable ? data[el] : unum(data[el], toUnit[el]);
      if (!isVariable && roundValue) {
        value = round(value);
      }
      result[el] = value;
    }
  });

  return result;
};
