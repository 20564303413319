import React from 'react';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { probabilities, timeCostView } from '~src/enums/simulations';
import { BarChart } from '@oliasoft-open-source/charts-library';
import { simulationChartType } from '~src/enums/compare-estimates';
import { getMaxProbability } from '~store/entities/simulations/simulations';
import { round } from '@oliasoft-open-source/units';
import { SimulationChartType } from '~src/enums/campaigns';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const RawSummaryChart = ({
  campaign,
  probability,
  dataEntity,
  primaryCurrency,
  chartType,
  controlsPortalId,
}) => {
  const { t } = useTranslation();

  const options = {
    chartStyling: {
      width: '80%',
    },
    legend: {
      position: 'top-left',
    },
    axes: {
      y: [
        {
          label: (() => {
            if (chartType === SimulationChartType.METERS_PER_DAY) {
              return t(translations.campaigns_metersPerDay);
            }
            return dataEntity === 0 ? t(translations.days) : primaryCurrency;
          })(),
        },
      ],
    },
    additionalAxesOptions: {
      stackedX: true,
    },
    tooltip: {
      scientificNotation: false,
    },
  };

  const labels = [];
  const adjustedDuration = [];
  const datasetData = campaign.estimates
    .map((estimate) => {
      const duration =
        dataEntity === 0
          ? getMaxProbability(
              estimate.simulation,
              simulationChartType.OPERATION_VS_DEPTH,
              probability,
              timeCostView.TIME,
            )
          : getMaxProbability(
              estimate.simulation,
              simulationChartType.OPERATION_COST_VS_DEPTH,
              probability,
              timeCostView.COSTS,
            );
      const metersPerDay =
        getMaxProbability(
          estimate.simulation,
          simulationChartType.OPERATION_VS_DEPTH,
          probability,
          timeCostView.DEPTH,
        ) / duration;

      if (duration) {
        labels.push(estimate.name);
        if (campaign.learningCurve) {
          adjustedDuration.push(
            round(duration * (1 - estimate.adjustment / 100), 3),
          );
        }
      }

      return chartType === SimulationChartType.TOTAL_DAYS
        ? duration
        : metersPerDay;
    })
    .filter((item) => item);
  const label = Object.keys(probabilities).find(
    (key) => probabilities[key] === probability,
  );

  const data = {
    labels,
    datasets: [
      {
        data: datasetData,
        label,
        backgroundColor: 'hsla(61, 33%, 60%, 0.85)',
        borderColor: 'hsl(61, 33%, 45%)',
        borderWidth: 0.9,
        barPercentage: 0.8,
      },
      ...(campaign.learningCurve
        ? [
            {
              data: adjustedDuration,
              label: `Adjusted ${label}`,
              backgroundColor: 'hsla(60, 20%, 50%, 0.85)',
              borderColor: 'hsl(60, 20%, 35%)',
              borderWidth: 0.9,
              barPercentage: 1,
            },
          ]
        : []),
    ],
  };

  return datasetData.length === 0 ? null : (
    <BarChart chart={{ data, options, controlsPortalId }} />
  );
};

export const SummaryChart = withErrorBoundary(RawSummaryChart);
