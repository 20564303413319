import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getOperations } from '~store/entities/activity-model/activity-model';
import { useParams } from 'react-router';
import ResultChart from '~src/views/simulations/result-overview/result-chart';
import ResultTable from '~src/views/simulations/result-overview/result-table';
import '~src/views/simulations/result-overview/style.less';

const CompareResultContent = ({
  simulationsResult,
  getOperations,
  chartKey,
  showTable,
  selectOptions,
  selectedItem,
  dataEntity,
}) => {
  const { project } = useParams();
  useEffect(() => {
    if (project) {
      getOperations(project);
    }
  }, [project]);

  const controlsChartPortalId = `result-chart-controls-${chartKey}`;
  const controlsTablePortalId = `result-table-controls-${chartKey}`;

  return (
    <div className="content-container">
      {showTable ? (
        <div id={controlsTablePortalId} />
      ) : (
        <div id={controlsChartPortalId} />
      )}
      <div className="content-inner">
        {showTable ? (
          <div>
            <ResultTable
              chartKey={chartKey}
              simulationsResult={simulationsResult}
              selectedItem={selectOptions[selectedItem]}
              selectedDataEntity={dataEntity}
              controlsPortalId={controlsTablePortalId}
            />
          </div>
        ) : (
          <div>
            <ResultChart
              key={chartKey}
              selectedItem={selectOptions[selectedItem]}
              selectedDataEntity={dataEntity}
              simulationsResult={simulationsResult}
              controlsPortalId={controlsChartPortalId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = { getOperations };

export default connect(null, mapDispatchToProps)(CompareResultContent);
