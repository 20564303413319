import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const sectionSchema = {
  type: 'object',
  properties: {
    name: {
      ...nonEmptyStringSchema,
    },
    operationHeader: { type: 'string' },
  },
};
