export const simulationChartType = Object.freeze({
  CUMULATIVE_PROBABILITY: 'cumulative-probability',
  OPERATION_DURATIONS: 'operation-durations',
  TIME_DEPTH: 'time-depth',
  ACTIVITY_DURATIONS: 'activity-durations',
  COST_ITEM_OVERVIEW: 'cost-item-overview',
  RISKS: 'risks',
  SECTION_DURATIONS: 'section-durations',
  MODIFIERS: 'modifiers',
  SECTION_WATERFALL: 'Section Waterfall',
});

export const timeCostView = Object.freeze({
  TIME: 'time',
  COST: 'cost',
  COSTS: 'costs',
  DEPTH: 'depth',
});

export const probabilities = Object.freeze({
  P10: '10',
  P50: '50',
  P90: '90',
  MEAN: 'mean',
});

export const simulationStatuses = Object.freeze({
  INITIALISING: 'Initialising',
  FINISHED: 'Finished',
});
