import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ListHeading,
  ListSubheading,
  Tree,
  Flex,
  Badge,
  Icon,
} from '@oliasoft-open-source/react-ui-library';
import {
  taskSelected,
  reorderTasks,
  tasksDeselected,
  addInitialTask,
} from '~store/entities/activity-model/activity-model';
import translations from '~src/internationalisation/translation-map.json';
import { selectExtendedLinkFields } from '~store/entities/activity-model/selectors';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const TasksList = ({
  tasks,
  activeOperation,
  setActiveForm,
  taskSelected,
  reorderTasks,
  tasksDeselected,
  onClickDeleteTask,
  addInitialTask,
  isAdding,
  treeRef,
  expandAllItems,
  collapseAllItems,
  openNode,
  isPageDisabled,
  projectId,
  linkedFields,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const onClickAdd = () => {
    tasksDeselected();
    addInitialTask(
      activeOperation.operationId,
      projectId,
      t(translations.activityModel_newActivity),
      null,
      linkedFields,
    );
    setActiveForm('task');
  };

  const onClickItem = (index) => {
    taskSelected(index);
    setActiveForm('task');
  };

  const onClickAddRisk = (id) => {
    tasksDeselected();
    addInitialTask(
      activeOperation.operationId,
      projectId,
      t(translations.activityModel_newRisk),
      id,
      linkedFields,
    );
    setActiveForm('task');
    openNode(id);
  };
  const onClickExpandCollapse = () => {
    if (expanded) {
      collapseAllItems();
    } else {
      expandAllItems();
    }
    setExpanded(!expanded);
  };

  const tasksList = activeOperation?.tasks
    ? activeOperation.tasks
        .map((task, i) =>
          task.isBranch
            ? null
            : {
                id: task.taskId,
                name: task.parentId ? (
                  <Flex
                    gap="var(--padding-xs)"
                    alignItems="center"
                    wrap={false}
                  >
                    <Badge title="RISK" small />
                    {task.name}
                    {task.withBranch ? <Icon icon="fork" /> : null}
                  </Flex>
                ) : (
                  task.name
                ),
                droppable: true,
                active: task.active,
                parent: task.parentId || 0,
                onClick: () => onClickItem(i),
                actions: [
                  {
                    label: t(translations.more),
                    subActions: [
                      {
                        label: t(translations.delete),
                        icon: 'delete',
                        onClick: () => onClickDeleteTask(task),
                        disabled: isPageDisabled,
                      },
                      ...(task.withBranch
                        ? []
                        : [
                            {
                              label: t(translations.activityModel_addRisk),
                              icon: 'add',
                              onClick: () => onClickAddRisk(task.taskId),
                              disabled: isPageDisabled,
                            },
                          ]),
                    ],
                  },
                ],
              },
        )
        .filter((item) => item)
    : [];

  return (
    <>
      <ListHeading
        name={
          activeOperation?.name || t(translations.activityModel_newOperation)
        }
      />
      <ListSubheading
        item={{
          name: `${t(translations.activities)} (${tasksList.length})`,
          actions: [
            {
              label: 'Expand/collapse',
              icon: expanded ? 'collapse all' : 'expand all',
              onClick: onClickExpandCollapse,
              disabled: isAdding || isPageDisabled,
            },
            {
              label: 'Add',
              icon: 'add',
              primary: true,
              onClick: onClickAdd,
              disabled: isAdding || isPageDisabled,
            },
          ],
        }}
      />
      <Tree
        list={{
          items: tasksList,
        }}
        draggable={!isPageDisabled}
        noHeader
        onListReorder={(reordered) => reorderTasks(reordered, tasks)}
        isInitialOpen={true}
        treeRef={treeRef}
        allowParentReassignment
      />
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  return {
    linkedFields: selectExtendedLinkFields({ entities }),
  };
};

const mapDispatchToProps = {
  taskSelected,
  reorderTasks,
  tasksDeselected,
  addInitialTask,
};

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(TasksList),
);

export { Container as TasksList };
