import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Heading,
  Field,
  Spacer,
  InputGroup,
} from '@oliasoft-open-source/react-ui-library';
import { initialOperation } from '~store/entities/user-settings/user-settings';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { operationsSchema } from '~schemas/settings/operations.schema';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import {
  TextArea,
  TemplateInput,
  InputWithVariable,
  Select,
} from '~common/form-inputs';
import { templateVariables } from '~src/enums/template-variables';
import {
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const OperationModal = ({
  setModalVisible,
  operation,
  unitSettings,
  updateOperation,
  settings,
  isAdding,
  operations,
  activeSection,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const activeOperations = operations.filter(
    (operation) => operation.sectionId === activeSection.sectionId,
  );

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: initialOperation,
    resolver: getResolver(operationsSchema),
  });

  useEffect(() => {
    const operationData = operation || initialOperation;
    const convertedOperation = convertToInputUnits(
      operationData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    reset(convertedOperation);
  }, [operation?.id, reset, unitSettings?.length]);
  const onSubmit = handleSubmit((data) => {
    const convertedOperation = convertToStorageUnits(data, ['from', 'to'], {
      from: 'm',
      to: 'm',
    });
    updateOperation(convertedOperation, settings, companyId);
  });

  const onClose = () => setModalVisible(false);

  const operationsList = activeOperations.map((operation) => ({
    label: operation.name,
    value: operation.sectionsOperationId,
  }));

  const [isEditing, setIsEditing] = useState(true);
  const { sectionsOperationId } = watch();

  useEffect(() => {
    if (sectionsOperationId && !isEditing) {
      const selectedOption = operationsList.find(
        (option) => option.value === sectionsOperationId,
      );
      setValue('name', selectedOption.label);
      setIsEditing(true);
    }
  }, [sectionsOperationId]);

  const handleOperationChange = (ev) => {
    setIsEditing(false);
    setValue('sectionsOperationId', ev.target.value);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: watch('name') || t(translations.activityModel_newOperation),
          content: (
            <>
              <form>
                <Field label={t(translations.operation)}>
                  <Select
                    name="sectionsOperationId"
                    control={control}
                    errors={errors}
                    options={operationsList}
                    placeholder={t(translations.activityModel_selectOperation)}
                    onChange={handleOperationChange}
                  />
                </Field>
                <Field label={t(translations.name)}>
                  <TemplateInput
                    name="name"
                    control={control}
                    errors={errors}
                    templateVariables={Object.values(templateVariables)}
                    description={`${t(
                      translations.variablesCanBeUsedForDynamicOperationNames,
                    )} ${t(translations.clickToCopyToClipboard)}`}
                    clickOnTemplate={true}
                  />
                </Field>
                <Heading>{t(translations.depth)}</Heading>
                <Spacer />
                <InputGroup>
                  <Field label={t(translations.from)}>
                    <InputWithVariable
                      name="from"
                      control={control}
                      unitkey={'length'}
                      unit={unitSettings?.length}
                      errors={errors}
                    />
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.to)}>
                    <InputWithVariable
                      name="to"
                      control={control}
                      unitkey={'length'}
                      unit={unitSettings?.length}
                      errors={errors}
                    />
                  </Field>
                </InputGroup>
                <Field label={t(translations.comments)}>
                  <TextArea
                    name="comments"
                    control={control}
                    errors={errors}
                    rows={5}
                  />
                </Field>
              </form>
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.save)}
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { operations } = selectSectionsDataStructure(entities);

  return {
    operations,
  };
};

const Container = withErrorBoundary(connect(mapStateToProps)(OperationModal), {
  isModal: true,
});

export { Container as OperationModal };
