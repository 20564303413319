import translations from '~src/internationalisation/translation-map.json';

export const addKpiSchema = {
  type: 'object',
  properties: {
    kpis: {
      type: 'array',
      items: { type: 'string' },
      minItems: 1,
      errorMessage: translations.activityModel_selectAtLeastOneKpi,
    },
  },
  required: ['kpis'],
  additionalProperties: false,
};
