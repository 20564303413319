import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import ajvKeywords from 'ajv-keywords';

const ajv = new Ajv({ allErrors: true, $data: true });

ajvErrors(ajv);
ajvKeywords(ajv, 'transform');

export const validate = (data, schema) => {
  const v = ajv.compile(schema);
  const valid = v(data);

  return { valid, errors: v.errors || [] };
};

export default ajv;
