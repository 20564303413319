import translations from '~src/internationalisation/translation-map.json';

export const operationsImportSchema = {
  type: 'object',
  properties: {
    template: { type: ['number', 'null'] },
    operations: {
      type: 'array',
      items: { type: 'string' },
      minItems: 1,
      errorMessage: translations.activityModel_selectAtLeastOneOperation,
    },
  },
  required: ['operations'],
  additionalProperties: false,
};
