import { HOST_BASE_URL } from '~common/urls/urls';

export default class ClientConfig {
  static get config() {
    const runtimeConfig = window.__runtimeConfigStatic;
    const apiBaseUrl = runtimeConfig?.api_base_url;
    const idpBaseUrl = runtimeConfig?.authentication_server_base_url;
    const dashboardUrl = runtimeConfig?.dashboard_url;
    const serverVersion = runtimeConfig?.app_version;

    return {
      baseApiUrl: apiBaseUrl,
      idpServerUrl: idpBaseUrl,
      dashboardUrl,
      hostBaseUrl: HOST_BASE_URL,
      applicationId: 'forecast-api',
      serverVersion,
    };
  }
}
export const autoSaveWait = 1500;

export const autoSaveWaitShort = 200;
