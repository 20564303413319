export const getMean = (risk, tasks) => {
  const {
    distribution = 'None',
    min = 0,
    most = 0,
    max = 0,
    withBranch = false,
  } = risk;

  if (withBranch) {
    // Calculate mean from child branches
    const branches = tasks.filter(
      (task) => task.isBranch && task.parentId === risk.taskId,
    );
    return branches.reduce(
      (mean, branch) => mean + branch.certainty * 0.01 * getMean(branch),
      0,
    );
  }
  switch (distribution) {
    case 'spike':
      return most;
    case 'uniform':
      return (max + min) / 2;
    case 'pert':
      return (max + 4 * most + min) / 6;
    default:
      return 0;
  }
};
