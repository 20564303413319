import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { sumBy, isEmpty, debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Heading, Table, Card } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import {
  riskValueUpdated,
  addInitialBranch,
  bulkBranchUpdate,
} from '~store/entities/activity-model/activity-model';
import { getRiskBranches } from '~store/entities/activity-model/selectors';
import { distributionTypes, estimateTypes } from '~src/enums/tasks';
import { getResolver } from '~src/validation/resolver';
import { tasksSchema } from '~schemas/activity-model/tasks.schema';
import { autoSaveWait } from '~src/config/config';

const BranchesTable = ({
  branches,
  addInitialBranch,
  isAdding,
  isPageDisabled,
  operationId,
  projectId,
  riskId,
  riskValueUpdated,
  estimateType,
  distributionType,
  onClickDeleteTask,
  debounceAddTask,
  bulkBranchUpdate,
}) => {
  const { t } = useTranslation();
  const debounceBulkBranchesUpdate = useRef(
    debounce(bulkBranchUpdate, autoSaveWait),
  );
  const quantity =
    estimateType === estimateTypes.OPERATION_TIME ? 'hr' : 'm/hr';
  const distributionFieldMap = {
    [distributionTypes.PERT]: [
      {
        key: 'min',
        name: t(translations.minimum),
        quantity,
      },
      {
        key: 'most',
        name: t(translations.mostLikely),
        quantity,
      },
      {
        key: 'max',
        name: t(translations.maximum),
        quantity,
      },
    ],
    [distributionTypes.UNIFORM]: [
      {
        key: 'min',
        name: t(translations.minimum),
        quantity,
      },
      {
        key: 'max',
        name: t(translations.maximum),
        quantity,
      },
    ],
    [distributionTypes.SPIKE]: [
      {
        key: 'most',
        name: t(translations.mostLikely),
        quantity,
      },
    ],
  };

  const rowsParams = [
    {
      key: 'name',
      name: t(translations.name),
    },
    {
      key: 'certainty',
      name: t(translations.probability),
      quantity: '%',
    },
    ...distributionFieldMap[distributionType],
    ...(estimateType === estimateTypes.OPERATION_SPEED
      ? [
          {
            key: 'from',
            name: t(translations.from),
            quantity: 'm',
          },
          {
            key: 'to',
            name: t(translations.to),
            quantity: 'm',
          },
        ]
      : []),
  ];

  const headerRow = rowsParams.map(({ name }) => ({
    value: name,
  }));

  const unitRow = rowsParams.map(({ quantity }) => {
    if (!quantity) return {};
    return {
      value: quantity,
    };
  });

  const actions = [
    {
      label: t(translations.add),
      disabled: isAdding || isPageDisabled,
      primary: true,
      icon: 'add',
      onClick: () =>
        addInitialBranch(
          operationId,
          projectId,
          t(translations.activityModel_newBranch),
          riskId,
        ),
    },
  ];
  const [branchesErros, setBranchesErros] = useState([]);
  const resolver = getResolver(tasksSchema);

  const validator = async (branchesData, onSubmit, branchId) => {
    const certaintySum = sumBy(branchesData, 'certainty');
    const resolvedBranches = branchesData.map((branch) =>
      resolver({ ...branch, distribution: distributionType, estimateType }),
    );
    Promise.all(resolvedBranches)
      .then((res) => {
        if (certaintySum === 100) {
          return res.map((el) => el.errors);
        } else {
          return res.map((el) => ({
            ...el.errors,
            certainty: t(translations.totalValueOfProbabilityShouldBe, {
              sum: 100,
            }),
          }));
        }
      })
      .then((res) => {
        setBranchesErros(res);
        if (onSubmit) {
          const branchIndex = branchesData?.findIndex(
            (branch) => branch.taskId === branchId,
          );
          const isBrancheValid =
            branchIndex !== -1 && isEmpty(res[branchIndex]);
          if (isBrancheValid) {
            onSubmit();
          }
        }
      });
  };
  useEffect(() => {
    validator(branches);
  }, [branches, distributionType, estimateType]);

  const tableRows = branches.map((branch, index) => {
    const errors = branchesErros?.[index];
    const cells = rowsParams.map(({ key }) => {
      const value = branch[key];

      return {
        type: key === 'name' ? 'Input' : 'NumberInput',
        allowEmpty: true,
        left: true,
        name: key,
        disabled: isPageDisabled,
        value,
        error: t(errors?.[key]),
        onChange: (e) => {
          let replaceComasToDotValue = e.target.value.replace(/,/, '.');
          if (key !== 'name') {
            replaceComasToDotValue =
              replaceComasToDotValue.endsWith('.') ||
              (replaceComasToDotValue.includes('.') &&
                replaceComasToDotValue.endsWith('0'))
                ? replaceComasToDotValue
                : parseFloat(replaceComasToDotValue) || 0;
          }

          riskValueUpdated({
            operationId,
            taskId: branch.taskId,
            value: replaceComasToDotValue,
            field: key,
          });
          const updatedBranch = {
            ...branch,
            operationId,
            [key]: replaceComasToDotValue,
          };
          const updatedIndex = branches.findIndex(
            (b) => b.taskId === branch.taskId,
          );
          const updatedBranches = branches.map((b) => ({ ...b, operationId }));
          updatedBranches[updatedIndex] = updatedBranch;
          const onSubmit = () =>
            key === 'certainty'
              ? debounceBulkBranchesUpdate.current({
                  activities: updatedBranches,
                  projectId,
                })
              : debounceAddTask.current(updatedBranch);
          validator(updatedBranches, onSubmit, branch.taskId);
        },
      };
    });

    return {
      cells,
      actions: [
        {
          label: t(translations.delete),
          icon: 'minus',
          onClick: () => onClickDeleteTask(branch),
          disabled: isPageDisabled,
        },
      ],
    };
  });

  const table = {
    bordered: false,
    headers: [{ cells: headerRow, actions }, { cells: unitRow }],
    rows: tableRows,
    footer: {},
  };

  return (
    <>
      <Card
        heading={
          <Heading>
            {t(translations.branches)}{' '}
            {branches.length ? ` (${branches.length})` : null}
          </Heading>
        }
        padding={0}
      >
        <Table table={table} />
      </Card>
    </>
  );
};

const mapStateToProps = ({ entities }, ownProps) => {
  const branches = getRiskBranches(
    { entities },
    ownProps.operationId,
    ownProps.riskId,
  );

  return { branches };
};

const mapDispatchToProps = {
  addInitialBranch,
  riskValueUpdated,
  bulkBranchUpdate,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(BranchesTable);

export { Container as BranchesTable };
