import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Dialog, Button } from '@oliasoft-open-source/react-ui-library';
import {
  removeSection,
  removeAllSections,
} from '~store/entities/activity-model/activity-model';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const SectionDeleteModal = ({
  removeSection,
  removeAllSections,
  setModalVisible,
  projectSectionId,
  setActiveForm,
  projectId,
}) => {
  const { t } = useTranslation();
  const onClose = () => setModalVisible(false);
  const onDelete = () => {
    if (projectSectionId) {
      removeSection(projectSectionId);
    } else {
      removeAllSections(projectId);
    }

    setModalVisible(false);
    setActiveForm(null);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(
            projectSectionId
              ? translations.activityModel_deleteSection
              : translations.activityModel_deleteAllSections,
          ),
          content: (
            <>
              {t(
                projectSectionId
                  ? translations.activityModel_areYouSureYouWantToDeleteThisSection
                  : translations.activityModel_areYouSureYouWantToDeleteAllSections,
              )}
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.delete)}
                colored
                onClick={onDelete}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  removeSection,
  removeAllSections,
};

const Container = withErrorBoundary(
  connect(null, mapDispatchToProps)(SectionDeleteModal),
  { isModal: true },
);

export { Container as SectionDeleteModal };
