import { ProjectCategoryType } from '~src/enums/projects';

export const projectSettingsSchema = {
  type: 'object',
  properties: {
    projectName: { type: ['string'] },
    primaryCurrency: { type: ['string'] },
    threadsPerSimulation: { type: ['number'] },
    category: {
      type: 'string',
      enum: Object.values(ProjectCategoryType),
    },
  },
  additionalProperties: false,
  required: ['projectName'],
};
