import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const operationsSchema = {
  type: 'object',
  properties: {
    id: {
      type: ['string', 'number'],
    },
    sectionType: { type: 'string' },
    name: {
      ...nonEmptyStringSchema,
    },
    from: {
      type: ['string', 'number'],
    },
    to: {
      type: ['string', 'number'],
    },
    comments: { type: 'string' },
    activities: { type: 'array' },
    operationCode: { type: ['string', 'null'] },
    subOperationCode: { type: ['string', 'null'] },
    sectionsOperationId: { type: ['string', 'null'] },
    active: { type: 'boolean' },
    depthFromLink: { type: ['string'] },
    depthToLink: { type: ['string'] },
  },
  required: ['name'],
  additionalProperties: false,
};
