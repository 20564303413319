import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { getWellDetails } from '~store/entities/well-details/well-details';
import { isEmpty } from 'lodash';
import { generateChart } from './generate-chart/generate-chart';
import styles from './utils/casing-renderer.module.less';
import {
  TCasingRendererDimensions,
  TWellDetails,
  TWellSchematic,
} from './utils/types';
import { calculateChartData } from './calculate-chart-data/calculate-chart-data';
import { useResizeCasingRenderer } from './utils/use-resize-casing-renderer';

type TCasingRendererComponentProps = {
  schematic: TWellSchematic[];
  well: TWellDetails;
  getWellDetails: any;
  dimensions: TCasingRendererDimensions;
};

const CasingRendererComponent = ({
  schematic,
  well,
  getWellDetails,
  dimensions,
}: TCasingRendererComponentProps) => {
  const { project } = useParams();
  const [chart, setChart] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (project && (isEmpty(well) || isEmpty(schematic))) {
      getWellDetails(project);
    }
  }, [project]);

  useEffect(() => {
    if (isEmpty(schematic)) {
      return;
    }
    (async () => {
      const chartData = calculateChartData(schematic, well, dimensions);
      setChart(generateChart({ chartData }));
    })();
  }, [schematic, well, dimensions]);

  if (!(schematic && well)) {
    console.error('Missing data');
    return null;
  }
  if (!chart) {
    return null;
  }

  return <div className={styles.mainWrapper}>{chart}</div>;
};

const mapStateToProps = ({ entities }: any) => {
  const {
    wellDetails: { well, schematic },
  } = entities;
  return { schematic, well };
};

const mapDispatchToProps = {
  getWellDetails,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CasingRendererComponent);

export { Container as CasingRendererComponent };

export const CasingRenderer = () => {
  const [dimensions, setDimensions] = useState<TCasingRendererDimensions>();
  const { width, height, ref } = useResizeCasingRenderer();

  useEffect(() => {
    setDimensions(
      width && height
        ? {
            width,
            height,
          }
        : undefined,
    );
  }, [width, height]);

  return (
    <div className={styles.responsiveWrapper} ref={ref}>
      {dimensions && <Container dimensions={dimensions} />}
    </div>
  );
};
