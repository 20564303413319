import translations from '~src/internationalisation/translation-map.json';

const percentageSchema = {
  type: 'number',
  minimum: -100,
  maximum: 100,
  errorMessage: translations.itMustBeAValidNumberBetween0And100,
};

export default percentageSchema;
