export const Sections = Object.freeze({
  Conductor: 'Conductor',
  Interface: 'Interface',
  Intermediate: 'Intermediate',
  LowerCompletion: 'Lower Completion',
  MiddleCompletion: 'Middle Completion',
  PilotHole: 'Pilot Hole',
  Production: 'Production',
  Reservoir: 'Reservoir',
  RigMove: 'Rig Move',
  Surface: 'Surface',
  UpperCompletion: 'Upper Completion',
  XMT: 'XMT',
});
