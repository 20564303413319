import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListHeading,
  ListSubheading,
  List,
} from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';

const SectionsList = ({ sectionTypes, sectionTypeSelected }) => {
  const { t } = useTranslation();

  return (
    <>
      <ListHeading name={t(translations.settings_linkedSections)} />
      <ListSubheading
        item={{
          name: `${t(t(translations.settings_sectionTypes))} (${
            sectionTypes.length
          })`,
        }}
      />
      <List
        list={{
          items: sectionTypes.map((section) => ({
            ...section,
            name: section.name,
            onClick: () => sectionTypeSelected(section.sectionId),
          })),
        }}
        noHeader
      />
    </>
  );
};

export { SectionsList };
