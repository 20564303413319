import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Field,
} from '@oliasoft-open-source/react-ui-library';
import { saveProjectHierarchy } from '~store/entities/projects/projects';
import { Input } from '~common/form-inputs';
import translations from '~src/internationalisation/translation-map.json';
import { getResolver } from '~src/validation/resolver';
import { designsSchema } from '~schemas/project/designs.schema';
import { project } from '~src/enums/projects';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { isNameIdentical } from '../utils';

const DesignModal = ({
  setModalVisible,
  saveProjectHierarchy,
  activeWellbore,
  editItem,
  setEditItem,
  isAdding,
  designs,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      wellbore: activeWellbore?.name,
      projectWellboreId: activeWellbore?.projectWellboreId,
      name: editItem?.name || '',
      projectDesignId: editItem?.projectDesignId || null,
    },
    resolver: getResolver(designsSchema),
  });
  const onSubmit = handleSubmit(async (data) => {
    if (
      isNameIdentical(
        data,
        designs,
        project.DESIGNS_LIST,
        activeWellbore,
        setError,
        'name',
      )
    ) {
      return;
    }
    delete data.wellbore;
    await saveProjectHierarchy({ design: { ...data }, companyId: company });
    setModalVisible(false);
    setEditItem(null);
  });
  const onClose = () => {
    if (!isAdding) {
      setModalVisible(false);
      setEditItem(null);
    }
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: editItem
            ? t(translations.projects_editDesign)
            : t(translations.projects_addNewDesign),
          width: '600px',
          content: (
            <form>
              <Field
                label={t(translations.wellbore)}
                labelLeft
                labelWidth="80px"
              >
                <Input
                  name="wellbore"
                  control={control}
                  errors={errors}
                  disabled={true}
                />
              </Field>
              <Field label={t(translations.design)} labelLeft labelWidth="80px">
                <Input
                  name="name"
                  control={control}
                  errors={errors}
                  disabled={isAdding}
                />
              </Field>
            </form>
          ),
          footer: (
            <>
              <Button
                label={t(translations.done)}
                colored
                onClick={onSubmit}
                loading={isAdding}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => ({
  isAdding: entities.projects.isAdding,
  designs: entities.projects.lists.designs,
});
const mapDispatchToProps = { saveProjectHierarchy };
const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(DesignModal),
  { isModal: true },
);

export { Container as DesignModal };
