import { useState } from 'react';
import { useParams } from 'react-router';
import { ConnectedProps, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Dialog,
  Button,
  Loader,
  Spinner,
  Table,
} from '@oliasoft-open-source/react-ui-library';
import {
  addConceptDesigns
} from '~store/entities/campaigns/campaigns';
import translations from '~src/internationalisation/translation-map.json';
import { pick } from 'lodash';
import { timeAgo } from '~src/store/entities/well-details/well-details';
import { ICampaignDesignItem } from '../interfaces/campaigns.interfaces';

type THierarchyDesignTableModal = PropsFromRedux & {
  setModalVisible: (param: boolean) => void,
  designs: ICampaignDesignItem[],
  isFetching: boolean,
  isAdding: boolean,
  name: string,
  campaignId: string,
  subjectId: string,
  countries: {countryId: string, code: string, name: string}[],
}

const HierarchyDesignTableModal = ({
  setModalVisible,
  designs,
  isFetching,
  isAdding,
  addConceptDesigns,
  name,
  campaignId,
  subjectId,
  countries,
}: THierarchyDesignTableModal) => {
  const { t } = useTranslation();
  const { company } = useParams();

  const [filters, setFilters] = useState<{[key: string]: string}>({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [designsValue, setDesignsValue] = useState<ICampaignDesignItem[]>([]);

  const designsWithCountryName = designs.map((design) => {
    const countryInfo = countries?.find(
      (country) => country.code === design.country,
    );
    return {
      ...design,
      country: countryInfo?.name,
      countryCode: countryInfo?.code,
    };
  });

  const onClose = () => setModalVisible(false);
  const onSubmit = () => {
    addConceptDesigns({
      companyId: company,
      name,
      designs: designsValue,
      campaignId,
      subjectId,
    });
    setModalVisible(false);
  };

  const headerLabels = [
    { label: t(translations.design), identifier: 'name' },
    { label: t(translations.country), identifier: 'country' },
    { label: t(translations.site), identifier: 'sort2' },
    { label: t(translations.wellbore), identifier: 'sort4' },
    { label: t(translations.lastAccessed), identifier: 'accessed' },
    { label: t(translations.lastModified), identifier: 'updated' },
    { label: t(translations.user), identifier: 'user_name' },
  ];

  const headerRow = headerLabels.map(({ label }) => ({
    value: label,
    width: 'auto',
  }));

  const headerFilter = headerLabels.map((header) => {
    const { identifier } = header;
    const filterValue = filters[identifier] ?? '';
    return {
      key: identifier,
      value: filterValue,
      type: 'Input',
      placeholder: t(translations.search),
      onChange: (ev: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPage(1);
        setFilters((prev) => ({
          ...prev,
          [identifier]: ev.target.value,
        }));
      },
    };
  });

  const startIndex = (selectedPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const filteredData = designsWithCountryName.filter((design) =>
    Object.keys(filters).every((key) => {
      const filterValue = filters[key];
      const designValue = design[key as keyof ICampaignDesignItem];
  
      return (
        filterValue === '' ||
        (designValue != null && designValue.toString().toLowerCase().includes(filterValue.toLowerCase()))
      );
    })
  );

  const rows = [
    ...filteredData.map((row) => {
      const formattedData = {
        ...row,
        accessed: timeAgo(row.accessed),
        updated: timeAgo(row.updated),
      };
      const checked = designsValue.some(
        (item) => item.datasetid === row.datasetid,
      );
      return {
        cells: [
          {
            type: 'CheckBox',
            checked,
            onChange: () => {
              if (checked) {
                setDesignsValue((prev) =>
                  prev.filter((item) => item.datasetid !== row.datasetid),
                );
              } else {
                setDesignsValue((prev) => [...prev, row]);
              }
            },
          },
          ...Object.entries(
            pick(formattedData, [
              'name',
              'country',
              'sort2',
              'sort4',
              'accessed',
              'updated',
              'user_name',
            ]),
          ).map(([key, value]) => ({
            key,
            value,
          })),
        ],
      };
    }),
  ];

  const filteredRows = rows.slice(startIndex, endIndex);

  const table = {
    fixedWidth: '100%',
    headers: [{ cells: [{}, ...headerRow] }, { cells: [{}, ...headerFilter] }],
    rows: filteredRows,
    footer: {
      pagination: {
        rowCount: rows.length,
        selectedPage,
        rowsPerPage: {
          onChange: (evt: React.ChangeEvent<HTMLSelectElement>) => setRowsPerPage(+evt.target.value),
          options: [
            { label: `10 / ${t(translations.page)}`, value: 10 },
            { label: `20 / ${t(translations.page)}`, value: 20 },
            { label: `50 / ${t(translations.page)}`, value: 50 },
          ],
          value: rowsPerPage,
        },
        onSelectPage: setSelectedPage,
        small: true,
      },
    },
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: t(translations.compare_addEstimates, {
            value: t(translations.designs),
          }),
          width: 'auto',
          content: (
            <div
              style={{
                position: 'relative',
                margin: 'calc(var(--padding) * -1)',
              }}
            >
              {isFetching && (
                <Loader text={t(translations.fetching)} theme="light" cover>
                  <Spinner dark />
                </Loader>
              )}
              <form style={{ padding: 'var(--padding)' }}>
                <Table table={table} />
              </form>
            </div>
          ),
          footer: (
            <>
              <Button
                label={
                  designsValue?.length > 0
                    ? `${t(translations.confirmSelection)} (${
                        designsValue?.length
                      })`
                    : t(translations.confirmSelection)
                }
                colored
                onClick={onSubmit}
                disabled={isAdding}
              />
              <Button
                label={t(translations.cancel)}
                onClick={onClose}
                disabled={isAdding}
              />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  addConceptDesigns,
};


const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Container = connector(HierarchyDesignTableModal);

export { Container as HierarchyDesignTableModal };