import ajv from './ajv';

export const getResolver = (schema) => {
  const validate = ajv.compile(schema);
  return async (data) => {
    const valid = validate(data);
    let errors = {};
    if (!valid && validate.errors) {
      validate.errors.forEach((error) => {
        const { instancePath, message } = error;
        const fieldName = instancePath.substring(
          instancePath.lastIndexOf('/') + 1,
        );
        errors[fieldName] = message;
      });
    } else {
      errors = {};
    }

    return {
      values: valid ? data : {},
      errors,
    };
  };
};
