import { CostType } from '~src/enums/cost-setup';
import nonEmptyStringSchema from '../common/non-empty-string.schema';

export const costItemsSchema = {
  type: 'object',
  properties: {
    name: {
      ...nonEmptyStringSchema,
    },
    type: {
      type: ['string'],
      enum: Object.values(CostType),
    },
    costItemId: { type: ['string', 'null'] },
    cost: { type: 'number' },
    currency: { type: 'string' },
    active: { type: 'boolean' },
    parentId: { type: ['string', 'null'] },
    sectionType: { type: 'string' },
    position: { type: 'number' },
    operations: {
      type: 'array',
      items: { type: 'string' },
    },
    quantity: { type: 'string' },
    length: { type: ['string', 'number'] },
    volume: { type: ['string', 'number'] },
    unitCost: { type: 'number' },
  },
  required: ['name'],
  additionalProperties: false,
};
