import { validate } from '~src/validation/ajv';
import numberSchema from '~schemas/common/number.schema';

export const validateNumber = (value) => {
  const validation = validate(value, numberSchema);
  if (!validation.valid) {
    return validation.errors[0].message;
  }
  return null;
};
